import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoX,
  LinkWrapper,
  Modal,
  Text
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader
} from "../../../theme";
import { useCallback, useState } from "react";
import { useTranslate } from "../../../contexts/SettingContext";

const CommonModal = ({ open, handleClose, title, content, callback }: any) => {
  const { t } = useTranslate();

  const [saving, setSaving] = useState(false);

  const handleYesClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setSaving(true);
      if (callback)
        callback()
          .then(() => {
            setSaving(false);
            handleClose();
          })
          .catch(() => {
            setSaving(false);
          });
    },
    [handleClose, callback]
  );

  return (
    <Modal width="auto" open={open} handleClose={() => handleClose()}>
      <Box height="auto" overflow="hidden">
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>{title}</Heading>
          <LinkWrapper onClick={() => handleClose()}>
            <Box padding="0">
              <IcoX margin="0" />
            </Box>
          </LinkWrapper>
        </FlexContainer>
        <Divider gap="3px" />
        <FlexContainer>
          <Text style={{ lineHeight: "1.5em", wordBreak: "break-word" }}>
            {content}
          </Text>
        </FlexContainer>
        <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
          <FlexContainer
            breakDirection="row"
            alignVertical="flex-end"
            gap={DefaultBoxGap}
          >
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => handleClose()}
            >
              {t("LabelNo")}
            </ButtonOutlinedDimmed>
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={(e) => handleYesClick(e)}
            >
              {saving ? (
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              ) : (
                t("LabelYes")
              )}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        </FlexContainer>
      </Box>
    </Modal>
  );
};

export default CommonModal;
