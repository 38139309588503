import {
  Box,
  ButtonOutlined,
  ButtonOutlinedDimmed,
  FlexContainer,
  HeadingSmall,
  Spacer,
  Text,
  TextExtraSmall,
  TextSmall
} from "llane-ui";
import useSubUsers from "../../../hooks/useSubUsers";
import NewSubUserModal from "../NewSubUserModal";
import { useState } from "react";
import {
  AccentTransparentBlack,
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../../../theme";
// import { useTranslate } from "../../../contexts/SettingContext";
import CommonModal from "../Confirmations/CommonModal";
import { deleteSubUser } from "../../../api/userApiCall";
import { useUser } from "../../../contexts/UserContext";
import SubUserEditModal from "../SubUserEditModal";
import { useTranslate } from "../../../contexts/SettingContext";

const TeamManagementSettings = () => {
  const { t } = useTranslate();
  const { subUsers, triggerFetch } = useSubUsers();
  const { accessToken } = useUser();
  // const { t } = useTranslate();

  const [openNew, setOpenNew] = useState(false);

  const [userIdToDelete, setUserIdToDelete] = useState<any>();
  const [userIdToEdit, setUserIdToEdit] = useState<any>();

  const callbackToRemoveUser = () => {
    return deleteSubUser(userIdToDelete, accessToken)
      .then(() => {
        triggerFetch();
      })
      .catch(() => {});
  };

  return (
    <>
      <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
        <FlexContainer
          alignVertical="space-between"
          alignHorizontal="center"
          breakDirection="row"
          width="100%"
        >
          <Text>{t("LabelTeamAccounts")}</Text>
          <ButtonOutlinedDimmed
            margin="0"
            borderRadius={DefaultBorderRadius}
            onClick={() => {
              setOpenNew(true);
            }}
            style={{ marginRight: 0 }}
          >
            {t("LabelNewAccount")}
          </ButtonOutlinedDimmed>
        </FlexContainer>
        <FlexContainer>
          <Box
            background={AccentTransparentBlack}
            gap={DefaultBoxGap}
            borderRadius="20px"
          >
            <HeadingSmall>{t("LabelActiveTeamAccounts")}</HeadingSmall>
            <Spacer gap="0" />
            <FlexContainer breakDirection="column">
              {!subUsers ? (
                <LoaderPaddingWrapper>
                  <DotsLoader>
                    <Dot delay="0s" />
                    <Dot delay="0.1s" />
                    <Dot delay="0.2s" />
                  </DotsLoader>
                </LoaderPaddingWrapper>
              ) : subUsers?.length === 0 ? (
                <TextSmall>{t("LabelNoActiveTeamAccounts")}</TextSmall>
              ) : (
                subUsers?.map((user: any) => (
                  <FlexContainer
                    key={user.user_id}
                    breakDirection="row"
                    gap="10px"
                    alignHorizontal="center"
                    alignVertical="flex-start"
                    style={{ flexWrap: "wrap" }}
                  >
                    {!user?.email_verified ? (
                      <TextExtraSmall style={{ wordBreak: "break-word" }}>
                        {t("LabelAwaitingEmailConfirmation")}
                      </TextExtraSmall>
                    ) : null}
                    <FlexContainer
                      breakDirection="row"
                      gap="10px"
                      alignHorizontal="center"
                    >
                      <FlexContainer>{user.email}</FlexContainer>
                      <FlexContainer style={{ whiteSpace: "nowrap" }}>
                        ID: AU-{user.tier_id}
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                      breakDirection="row"
                      gap="10px"
                      alignHorizontal="center"
                      alignVertical="flex-start"
                    >
                      <ButtonOutlined
                        height="24px"
                        padding="0px 10px"
                        onClick={() => {
                          setUserIdToEdit(user.user_id);
                        }}
                      >
                        {t("LabelEdit")}
                      </ButtonOutlined>
                      <ButtonOutlined
                        height="24px"
                        padding="0px 10px"
                        onClick={() => {
                          setUserIdToDelete(user.user_id);
                        }}
                      >
                        {t("LabelDelete")}
                      </ButtonOutlined>
                    </FlexContainer>
                  </FlexContainer>
                ))
              )}
            </FlexContainer>
          </Box>
        </FlexContainer>
      </FlexContainer>
      <NewSubUserModal
        open={openNew}
        triggerFetch={triggerFetch}
        handleClose={() => {
          setOpenNew(false);
        }}
      />
      <SubUserEditModal
        open={Boolean(userIdToEdit)}
        handleClose={() => {
          setUserIdToEdit(undefined);
        }}
        triggerFetch={triggerFetch}
        user_id={userIdToEdit}
      />
      <CommonModal
        open={Boolean(userIdToDelete)}
        handleClose={() => {
          setUserIdToDelete(undefined);
        }}
        callback={callbackToRemoveUser}
        content={t("LabelDeleteConfirmation")}
      />
    </>
  );
};

export default TeamManagementSettings;
