import { createContext, useContext } from "react";
import { useProvideAuth } from "../hooks/useAuth";

export const UserContext = createContext<any>({});

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    user,
    permission,
    accessToken,
    setAccessToken,
    triggerFetch,
    logout,
    loginType,
    setLoginType
  } = useProvideAuth();

  return (
    <UserContext.Provider
      value={{
        user,
        permission,
        accessToken,
        setAccessToken,
        triggerFetch,
        logout,
        loginType,
        setLoginType
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
