import { useMemo, useRef, useState } from "react";
import {
  Button,
  FlexContainer,
  HeadingLarge,
  InputOutlined,
  PageWrapper,
  Spacer,
  Text,
  TextExtraSmallError,
  TextExtraSmallInfo,
  TextExtraSmallSuccess
} from "llane-ui";
import { useTranslate } from "../../contexts/SettingContext";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { testPassword, validateEmail } from "../../utils";
import { userSignUp } from "../../api/userApiCall";
import { toast } from "react-toastify";
import { Dot, DotsLoader } from "../../theme";
import { useUser } from "../../contexts/UserContext";
import { RECAPTCHA_KEY } from "../../constants";

function Signup() {
  const { t } = useTranslate();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const { setAccessToken } = useUser();

  const emailError = useMemo(() => {
    if (!email || email === "" || validateEmail(email)) return null;
    else return "No valid email";
  }, [email]);

  const passwordStrength = useMemo(() => {
    if (!password || password === "") return 0;
    else if (password.length < 8) {
      return 1;
    } else {
      return testPassword(password);
    }
  }, [password]);

  const confirmError = useMemo(() => {
    if (
      !confirmPassword ||
      confirmPassword === "" ||
      confirmPassword === password
    )
      return null;
    else return "Password mismatch";
  }, [confirmPassword, password]);

  const disabled = useMemo(() => {
    if (
      emailError ||
      confirmError ||
      passwordStrength < 3 ||
      !confirmPassword?.length ||
      !firstName?.length ||
      !lastName?.length
    )
      return true;
    else return false;
  }, [
    firstName,
    lastName,
    emailError,
    confirmError,
    passwordStrength,
    confirmPassword
  ]);

  const recaptchaRef = useRef<any>();

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSignUp();
    }
  };

  const handleSignUp = () => {
    const captcha = recaptchaRef.current?.getValue();
    if (!captcha) {
      toast.warning("Please complete recaptcha.");
      return;
    }
    if (
      !emailError &&
      !confirmError &&
      passwordStrength > 2 &&
      email &&
      password &&
      firstName?.length &&
      lastName?.length
    ) {
      const payload = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        recaptcha: captcha
      };
      setLoading(true);
      userSignUp(payload)
        .then((response) => {
          toast.success("Account Registered");
          if (response?.token) {
            setAccessToken(response?.token);
          }
          setEmail("");
          setFirstName("");
          setLastName("");
          setPassword("");
          setConfirmPassword("");
          // navigate("/confirm");
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <PageWrapper>
        <Spacer gap="5%" />
        <FlexContainer
          breakDirection="row"
          alignHorizontal="center"
          alignVertical="center"
          onKeyDown={_handleKeyDown}
        >
          <FlexContainer padding="1px" alignText="center" gap="10px">
            <HeadingLarge>{t("LabelCreateAnAccount")}</HeadingLarge>
            <Text>{t("LabelEnterDetails")}</Text>
            <Spacer />
            <FlexContainer gap="10px" margin="auto" width="450px">
              <InputOutlined
                minWidth="100%"
                placeholder={t("LabelFirstName")}
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFirstName(e.target.value)
                }
              />
              <InputOutlined
                minWidth="100%"
                placeholder={t("LabelLastName")}
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLastName(e.target.value)
                }
              />
              <InputOutlined
                minWidth="100%"
                placeholder={t("LabelEmail") + "*"}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              {emailError && (
                <TextExtraSmallError
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  {emailError}
                </TextExtraSmallError>
              )}
              <InputOutlined
                minWidth="100%"
                placeholder={t("LabelPassword")}
                type="password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
              {passwordStrength === 1 ? (
                <TextExtraSmallError
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  Your password is too week.
                </TextExtraSmallError>
              ) : passwordStrength === 2 ? (
                <TextExtraSmallError
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  Try adding numbers and symbols.
                </TextExtraSmallError>
              ) : passwordStrength === 3 ? (
                <TextExtraSmallInfo
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  Your password is acceptable.
                </TextExtraSmallInfo>
              ) : passwordStrength > 3 ? (
                <TextExtraSmallSuccess
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  You are using a strong password.
                </TextExtraSmallSuccess>
              ) : null}
              <InputOutlined
                minWidth="100%"
                placeholder={t("Confirm Password")}
                type="password"
                value={confirmPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
              />
              {confirmError && (
                <TextExtraSmallError
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  {confirmError}
                </TextExtraSmallError>
              )}
              <ReCAPTCHA
                ref={recaptchaRef as any}
                style={{ display: "inline-block" }}
                size="normal"
                sitekey={RECAPTCHA_KEY}
              />
              <Button
                disabled={disabled}
                width="100%"
                onClick={() => handleSignUp()}
              >
                {loading ? (
                  <DotsLoader>
                    <Dot delay="0s" />
                    <Dot delay="0.1s" />
                    <Dot delay="0.2s" />
                  </DotsLoader>
                ) : (
                  `${t("LabelCreateAccount")}`
                )}
              </Button>
              <FlexContainer breakDirection="row" gap="20px">
                <Link
                  to="/login"
                  style={{ textDecoration: "none", margin: "auto" }}
                >
                  <Text>
                    Already have an account? <b>Click here to login.</b>
                  </Text>
                </Link>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </PageWrapper>
    </>
  );
}

export default Signup;
