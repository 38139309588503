import { deleteApiCall, getApiCall, postApiCall } from ".";
import { LoginType, SignUpType } from "../types/user";

export const userLogin = (payload: LoginType) => {
  const url = "/login";
  return postApiCall(url, payload);
};

export const userSignUp = (payload: SignUpType) => {
  const url = "/signup";
  return postApiCall(url, payload);
};

export const getUserSessions = (token = "token") => {
  const url = "/sessions";
  return getApiCall(url, token);
};

export const deleteSession = (session_id: string, token = "token") => {
  const url = `/session/${session_id}`;
  return deleteApiCall(url, token);
};

export const userLogout = (token = "token") => {
  const url = `/logout`;
  return getApiCall(url, token);
};

export const getUser = (token: string) => {
  const url = "/profile";
  return getApiCall(url, token);
};

export const updateProfiile = (payload: any, token = "") => {
  const url = `/profile/update`;
  return postApiCall(url, payload, token);
};

export const changePassword = (payload: any, token = "") => {
  const url = `/profile/change-password`;
  return postApiCall(url, payload, token);
};

export const getSubUsers = (token = "") => {
  const url = `/sub-users`;
  return getApiCall(url, token);
};

export const getSubUerDetail = (user_id: number, token = "") => {
  const url = `/sub-user/${user_id}`;
  return getApiCall(url, token);
};

export const createSubUser = (payload: any, token = "") => {
  const url = `/sub-user/new`;
  return postApiCall(url, payload, token);
};

export const updateSubUser = (payload: any, token = "") => {
  const url = `/sub-user/update`;
  return postApiCall(url, payload, token);
};

export const updateSubUserPassword = (payload: any, token = "") => {
  const url = `/sub-user/update/password`;
  return postApiCall(url, payload, token);
};

export const deleteSubUser = (user_id: number, token = "") => {
  const url = `/sub-user/${user_id}`;
  return deleteApiCall(url, token);
};

export const sendEmail = (payload: any) => {
  const url = `/auth/confirm-email`;
  return postApiCall(url, payload);
};

export const verifyEmail = (link: string) => {
  const url = `/auth/verify-email/${link}`;
  return getApiCall(url);
};
