import { useSubscriptionHistory } from "../hooks/useSubscription";
import {
  Box,
  Divider,
  FlexContainer,
  HeadingSmall,
  IcoLink,
  Spacer,
  TextSmall,
  TextSmallError,
  TextSmallSuccess
} from "llane-ui";
import {
  AccentTransparentBlack,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../theme";
import { useTranslate } from "../contexts/SettingContext";
import { getDateStr } from "../utils";

const BillingHistory = () => {
  const { invoices } = useSubscriptionHistory();

  const { t } = useTranslate();

  return (
    <FlexContainer>
      <Box
        background={AccentTransparentBlack}
        gap={DefaultBoxGap}
        borderRadius="20px"
      >
        <HeadingSmall>{t("LabelBillingHistory")}</HeadingSmall>
        <Spacer gap="0" />
        <>
          {invoices ? (
            invoices.length === 0 ? (
              <TextSmall>{t("LabelNoItemsToShow")}</TextSmall>
            ) : (
              <>
                <Divider gap="0" />
                {invoices.map((invoice: any) => (
                  <>
                    <FlexContainer
                      breakDirection="row"
                      gap="10px"
                      style={{ flexWrap: "wrap" }}
                    >
                      <TextSmall
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(invoice.hosted_invoice_url, "_blank");
                        }}
                      >
                        {getDateStr(Number(invoice.created * 1000))}
                        <IcoLink size="10px" margin="0 0 0 5px" />
                      </TextSmall>

                      <TextSmall>
                        $
                        {invoice?.amount_paid
                          ? Number(invoice.amount_paid) / 100
                          : 0}
                      </TextSmall>

                      {invoice.paid ? (
                        <TextSmallSuccess>Paid</TextSmallSuccess>
                      ) : (
                        <TextSmallError>Fail</TextSmallError>
                      )}

                      <TextSmall>
                        {invoice?.lines?.data?.[
                          invoice?.lines?.data?.length - 1
                        ]?.price?.nickname ?? ""}
                      </TextSmall>
                    </FlexContainer>
                    <Divider gap="0" />
                  </>
                ))}
              </>
            )
          ) : (
            <LoaderPaddingWrapper>
              <DotsLoader>
                <Dot delay="0s" />
                <Dot delay="0.1s" />
                <Dot delay="0.2s" />
              </DotsLoader>
            </LoaderPaddingWrapper>
          )}
        </>
      </Box>
    </FlexContainer>
  );
};

export default BillingHistory;
