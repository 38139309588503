import { useCallback, useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";
import {
  fetchSubscription,
  fetchSubscriptionHistory
} from "../api/subscriptionApiCall";

export const useSubscription = () => {
  const [subscription, setSubscription] = useState<any>();

  const { accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    setSubscription(undefined);
    fetchSubscription(accessToken)
      .then((response: any) => {
        if (response) setSubscription(response);
        else setSubscription(null);
      })
      .catch(() => {});
  }, [accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return {
    subscription,
    triggerFetch
  };
};

export const useSubscriptionHistory = () => {
  const [invoices, setInvoices] = useState<any>();
  const [subscriptions, setSubscriptions] = useState<any>();

  const { accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    fetchSubscriptionHistory(accessToken)
      .then((response: any) => {
        setSubscriptions(response?.subscriptions);
        setInvoices(response?.invoices ?? []);
      })
      .catch(() => {});
  }, [accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return {
    subscriptions,
    invoices,
    triggerFetch
  };
};
