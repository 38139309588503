import {
  Box,
  ButtonOutlinedDimmed,
  DefaultGap,
  Divider,
  Dropdown,
  DropdownLink,
  FlexContainer,
  Heading,
  IcoImage,
  IcoX,
  InputOutlined,
  LinkWrapper,
  Modal
} from "llane-ui";
import { useState } from "react";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  ProcessingJsxWrapper,
  PromptButton
} from "../../theme";
import { useTranslate } from "../../contexts/SettingContext";
import { usePrompt } from "../../contexts/PromptContext";
import { useUser } from "../../contexts/UserContext";
import { sendMessageToAi } from "../../api/aiApiCall";
import { toast } from "react-toastify";
import { ChatMessage } from "../../types/prompt";

const ImageStyles = [
  "Default",
  "3D Model",
  "Digital",
  "Drawing",
  "Illustration",
  "Old Photograph",
  "Photograph",
  "Realistic",
  "Sketch"
];

const ColorStyles = [
  "Balanced",
  "Colourful",
  "Contrasting",
  "Faded",
  "Gradient",
  "Pastel",
  "Smooth",
  "Sepia"
];

const ImageGenerationModal = () => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { setMessages, abortController, setIsAwaitingResponse } = usePrompt();
  const { accessToken } = useUser();

  const [imageStyle, setImageStyle] = useState<string | null>(null);
  const [colorStyle, setColorStyle] = useState<string | null>(null);

  const [text, setText] = useState("");

  const handleGenerate = async () => {
    if (!text || text === "" || !imageStyle || !colorStyle) return;
    try {
      setSaving(true);
      setIsAwaitingResponse(true);
      let prompt = text;
      prompt += `, using ${imageStyle} image style, and a ${colorStyle} colour style.`;

      const payload = {
        content: prompt,
        context: []
      };

      const controller = new AbortController();

      if (abortController) abortController.current = controller;

      const signal = controller.signal;

      setMessages((prev) => [
        ...prev,
        { subject: "User", content: "🖼️ Smart Image Generation Tool", type: "text" }
      ]);

      const response = await sendMessageToAi(
        "ArcadiaImage",
        payload,
        accessToken,
        signal
      ).catch((err) => {
        if (err.message === "Too many requests") {
          const { ratelimit } = err;
          if (ratelimit) {
            const min = Math.floor(ratelimit.msBeforeNext / 1000 / 60) ?? 1;
            toast.error(
              `You have exceeded your usage limit for this type of request. Please try again in ${min} minutes.`
            );
          }
        } else if (err.message === "Bad Words") {
          setMessages((prev) => [
            ...prev,
            {
              subject: "AI",
              content:
                "I'm sorry but your request does not comply with our User Safety Policy and will not be processed. Repeated infringements may result in your account being banned.",
              type: "text"
            }
          ]);
        }
        return null;
      });

      if (response) {
        const data: ChatMessage = {
          subject: "AI",
          content: response.images[0].toString("base64"),
          type: "photo"
        };

        setMessages((prev) => [...prev, data]);
      }
    } catch (err) {
    } finally {
      setSaving(false);
      setIsAwaitingResponse(false);
    }
  };

  return (
    <>
      <FlexContainer margin="0">
        <PromptButton onClick={() => setOpen(!open)}>
          <ProcessingJsxWrapper>
            <IcoImage size="20px" margin="0 2px 0 0" />
          </ProcessingJsxWrapper>
        </PromptButton>
      </FlexContainer>
      <Modal
        width="auto"
        open={open}
        handleClose={() => {
          if (!saving) setOpen(false);
        }}
      >
        <Box height="auto" width="540px" maxHeight="90vh" overflow="hidden">
          <FlexContainer alignVertical="space-between" breakDirection="row">
            <Heading>{t("LabelGenerateImage")}</Heading>
            <LinkWrapper onClick={() => setOpen(false)}>
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            </LinkWrapper>
          </FlexContainer>
          <Divider gap="3px" />
          <FlexContainer
            breakDirection="column"
            width="100%"
            gap={DefaultBoxGap}
            padding="1rem 10px 1rem 10px"
            style={{ overflow: "auto", boxSizing: "border-box" }}
          >
            <FlexContainer
              breakDirection="row"
              alignHorizontal="center"
              alignVertical="flex-start"
              gap={DefaultGap}
              style={{ flexWrap: "wrap", width: "100%" }}
            >
              <FlexContainer width="calc(50% - 0.5rem)" alignHorizontal="flex-start">
              <Dropdown title={imageStyle ?? t("LabelImageStyle")} maxHeight="240px">
                {ImageStyles.map((style) => (
                  <DropdownLink
                    onClick={() => {
                      setImageStyle(style);
                    }}
                  >
                    {style}
                  </DropdownLink>
                ))}
              </Dropdown>
              </FlexContainer>
              <FlexContainer width="calc(50% - 0.5rem)" alignHorizontal="flex-end">
              <Dropdown title={colorStyle ?? t("LabelColourStyle")} maxHeight="240px">
                {ColorStyles.map((style) => (
                  <DropdownLink
                    onClick={() => {
                      setColorStyle(style);
                    }}
                  >
                    {style}
                  </DropdownLink>
                ))}
              </Dropdown>
              </FlexContainer>
            </FlexContainer>
            <InputOutlined
              width="100%"
              placeholder={t("LabelImgGenPlaceholder")}
              value={text}
              onChange={(e: any) => {
                setText(e.target.value);
              }}
            />
          </FlexContainer>
          <Divider gap="3px" />
          {/* Buttons */}
          <FlexContainer
            breakDirection="row"
            alignVertical="flex-end"
            gap={DefaultBoxGap}
          >
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => setOpen(false)}
            >
              {t("LabelCancel")}
            </ButtonOutlinedDimmed>
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              disabled={!text || text === "" || !imageStyle || !colorStyle}
              onClick={() => {
                handleGenerate();
              }}
            >
              {saving ? (
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              ) : (
                t("LabelGenerate")
              )}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        </Box>
      </Modal>
    </>
  );
};

export default ImageGenerationModal;
