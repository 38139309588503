import { AppWrapper, LlaneUi, useThemeToggle } from "llane-ui";
import AppHeader from "../components/Header";
import AppFooter from "../components/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import NewPromptModal from "../components/Modals/Confirmations/NewPromptModal";
import OpenPromptModal from "../components/Modals/Confirmations/OpenPromptModal";
import SavePromptModal from "../components/Modals/Confirmations/SavePromptModal";
import UpdatePromptModal from "../components/Modals/Confirmations/UpdatePromptModal";
import { useUserSetting } from "../contexts/SettingContext";
import { Dot, DotsLoader, LoaderPaddingWrapper } from "../theme";
import { useUser } from "../contexts/UserContext";
import SummariseModal from "../components/Modals/SummariseModal";
import { useEffect, useMemo } from "react";
import LoadingModal from "../components/Modals/LoadingModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionModal from "../components/Modals/SubscriptionModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51OoYFdS0sSMzsn7nbF1egYT6ma9b8NU8L66yHQk2RwM7gS8k59tPXGhjdR8ROZUQgovYYHIgaM8mdwBP01hzhEjx0053YI3KWB"
);

const Modals = () => {
  return (
    <>
      <NewPromptModal />
      <OpenPromptModal />
      <SavePromptModal />
      <UpdatePromptModal />
      <SummariseModal />
      <LoadingModal />
      <SubscriptionModal />
    </>
  );
};

const Content = () => {
  const { setting } = useUserSetting();
  const { setTheme } = useThemeToggle();

  const currentTheme = useMemo(() => {
    if (setting) return setting.theme;
    else return undefined;
  }, [setting]);

  useEffect(() => {
    if (currentTheme) {
      setTheme(currentTheme);
    }
  }, [currentTheme, setTheme]);

  return (
    <Elements stripe={stripePromise}>
      <AppWrapper
        alignHorizontal="center"
        alignVertical="top"
        height="100vh"
        style={{ overflow: "hidden" }}
      >
        <AppHeader />
        <Outlet />
        <AppFooter />
        <Modals />
      </AppWrapper>
    </Elements>
  );
};

const AppLayout = () => {
  const { setting } = useUserSetting();

  const { user } = useUser();

  const navigate = useNavigate();

  const currentTheme = useMemo(() => {
    if (setting) return setting.theme;
    else return undefined;
  }, [setting]);

  if (user === null) {
    navigate("/login");
  }

  if (user && !user.email_verified) {
    navigate("/confirm");
  }

  return (
    <>
      {!setting || (user && !currentTheme) ? (
        <AppWrapper alignHorizontal="center" alignVertical="top">
          <LoaderPaddingWrapper>
            <DotsLoader>
              <Dot delay="0s" />
              <Dot delay="0.1s" />
              <Dot delay="0.2s" />
            </DotsLoader>
          </LoaderPaddingWrapper>
        </AppWrapper>
      ) : (
        <LlaneUi initialTheme={setting.theme}>
          <Content />
        </LlaneUi>
      )}
    </>
  );
};

export default AppLayout;
