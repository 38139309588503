import React, {
  useState,
  ReactNode,
  ReactElement,
  cloneElement,
  Children,
  useRef,
  useEffect
} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  DropdownLinkHoverBackground,
  TextColor,
  Text,
  IcoChevronDown,
  Sp,
  Span,
  BorderColor,
  AppBackground
} from "..";
import {
  BorderThickness,
  DefaultBoxShadow,
  DropdownButtonBorderRadius,
  DropdownContainerBorderRadius,
  DropdownContainerPadding,
  DropdownFontSize,
  DropdownLinkPadding,
  DropdownPadding
} from "../BaseThemeStyle";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

interface ComponentProps {
  color?: string;
  colorHover?: string;
  size?: string;
  weight?: string;
  decoration?: string;
  decorationHover?: string;
  background?: string;
  backgroundHover?: string;
  padding?: string;
  borderRadius?: string;
  borderThickness?: string;
  borderColor?: string;
  minWidth?: string;
  boxShadow?: string;
  isOpen?: boolean;
  mobileBreakingPoint?: string;
  title?: string;
  fontColor?: string;
  fontSize?: string;
  margin?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  position?: "left" | "right";
  top?: string;
  left?: string;
  right?: string;
}

export const DropdownLink = styled.a<ComponentProps>`
  display: block;
  padding: ${(props) => props.padding || DropdownLinkPadding};
  color: ${(props) => props.color || TextColor};
  text-decoration: none;
  width: 100%;
  min-width: 75px;
  cursor: pointer;
  white-space: nowrap;
  z-index: 1001;
  &:hover {
    background-color: ${(props) =>
      props.backgroundHover || DropdownLinkHoverBackground};
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropdownContainer = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${AppBackground};
  padding: ${(props) => props.padding || DropdownContainerPadding};
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  width: ${(props) => props.width || "100%"};
  z-index: 1000;
  max-height: ${(props) => props.maxHeight || "500px"};
`;

const DropdownContentWrapper = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: calc(100%);
  border-radius: ${DropdownContainerBorderRadius};
  border: ${BorderThickness} solid transparent;
  box-shadow: ${DefaultBoxShadow};
  overflow: hidden;
  box-sizing: border-box;
  width: fit-content;
  min-width: 200px;
  height: fit-content;
  z-index: 1400;
  backdrop-filter: blur(0px);
`;

const DropdownToggle = styled.div<ComponentProps>`
  background: transparent;
  border: ${BorderThickness} solid ${BorderColor};
  border-radius: ${DropdownButtonBorderRadius};
  color: ${TextColor};
  font-size: ${DropdownFontSize};
  padding: ${DropdownPadding};
  cursor: pointer;
  text-align: center;
  width: ${(props) => props.width || "100%"};
  box-sizing: border-box;
  white-space: nowrap;
`;

interface DropdownProps extends ComponentProps {
  children: ReactNode | ReactNode[];
  title?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  title = "Dropdown Title",
  maxHeight,
  width,
  position = "left"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<any>();

  const contentRef = useRef<any>();

  useOnClickOutside(contentRef, () => {
    setIsOpen(false);
  });

  const cloneWithClickHandler = (child: ReactElement) => {
    const originalOnClick = child.props.onClick;

    const handleChildClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      originalOnClick?.(event); // Safely call the original onClick if it exists
      setIsOpen(false);
    };

    return cloneElement(child, { onClick: handleChildClick });
  };

  const enhancedChildren = Children.map(children, (child) =>
    React.isValidElement(child) ? cloneWithClickHandler(child) : child
  );

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        const rect = node?.current?.getBoundingClientRect();
        if (contentRef.current) {
          contentRef.current.style.position = "fixed";
          if (position === "left") {
            contentRef.current.style.left = rect.x + "px";
          } else {
            contentRef.current.style.right =
              window.innerWidth - (rect.x + rect.width) + "px";
          }

          contentRef.current.style.top = rect.top + rect.height + 8 + "px";
        }
      }
    };

    window.addEventListener("resize", () => {
      handleResize();
    });

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [position, isOpen, contentRef, node]);

  return (
    <DropdownWrapper ref={node as any}>
      <DropdownToggle onClick={() => handleOpen()}>
        <Text>
          {title}
          <Sp />
          <Span>
            <IcoChevronDown size="0.9rem" margin="auto auto -3px auto" />
          </Span>
        </Text>
      </DropdownToggle>
      {isOpen
        ? ReactDOM.createPortal(
            <DropdownContentWrapper ref={contentRef}>
              <DropdownContainer maxHeight={maxHeight} width={width}>
                {enhancedChildren}
              </DropdownContainer>
            </DropdownContentWrapper>,
            document.body
          )
        : null}
    </DropdownWrapper>
  );
};
