import {
  AppBackground,
  BorderColor,
  Box,
  Divider,
  FlexContainer,
  HeaderLinkHoverBackground,
  Heading,
  HideMobile,
  IcoCreditCard,
  IcoHome,
  IcoLock,
  IcoSettings,
  IcoUser,
  IcoUsers,
  IcoX,
  LinkWrapper,
  Modal,
  TextColor,
  TextSmall
} from "llane-ui";
import { useState } from "react";
import GeneralSettings from "./GeneralSettings";
import SecuritySettings from "./SecuritySettings";
import TeamManagementSettings from "./TeamManagementSettings";
import OrganisationSettings from "./OrganisationSettings";
import BillingSettings from "./BillingSettings";
import {
  AccentTransparentBlack,
  DefaultBorderRadius,
  NeutralGray
} from "../../../theme";
import ProfileSettings from "./ProfileSettings";
import { useTranslate } from "../../../contexts/SettingContext";
import styled from "styled-components";
import { SettingContainer, TabItem, TabsContainer } from "./styled";
import { useUser } from "../../../contexts/UserContext";

enum SettingOption {
  General,
  Profile,
  Organisation,
  TeamManagement,
  Security,
  Billing
}

const StyledScollbarContainer = styled(FlexContainer)`
  scrollbar-width: thin;
  scrollbar-color: ${BorderColor} ${AppBackground};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5274e;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ff0000;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.025);
    border-radius: 6px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SettingsIconWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  color: ${TextColor};
  font-size: auto;
  font-weight: normal;
  text-decoration: none;
  padding: 0 1rem;
  background: transparent;
  &:hover {
    color: ${TextColor};
    text-decoration: none;
    background: ${HeaderLinkHoverBackground};
  }
  @media (max-width: 769px) {
    padding: 0;
  }
`;

const SettingModal = () => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState<SettingOption>(SettingOption.General);

  const { permission, user } = useUser();

  return (
    <>
      <SettingsIconWrapper
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}
      >
        <IcoSettings />
      </SettingsIconWrapper>
      <Modal width="auto" open={open} handleClose={() => setOpen(false)}>
        <Box
          height="auto"
          minWidth="50vw"
          maxWidth="100vw"
          minHeight="60vh"
          maxHeight="100vh"
        >
          <FlexContainer alignVertical="space-between" breakDirection="row">
            <Heading>{t("LabelSettings")}</Heading>
            <LinkWrapper
              onClick={(e: any) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            </LinkWrapper>
          </FlexContainer>
          <Divider gap="3px" />
          <SettingContainer gap="10px">
            <TabsContainer breakDirection="column" minWidth="240px">
              <LinkWrapper
                onClick={() => {
                  setOption(SettingOption.General);
                }}
                decorationHover="none"
              >
                <TabItem
                  breakDirection="row"
                  backgroundHover={AccentTransparentBlack}
                  padding="15px"
                  borderRadius={DefaultBorderRadius}
                  active={option === SettingOption.General}
                >
                  <IcoSettings
                    size="20px"
                    margin="auto 10px 0px 0px"
                    color={NeutralGray}
                  />
                  <HideMobile>
                    <TextSmall margin="3px 0">{t("LabelGeneral")}</TextSmall>
                  </HideMobile>
                </TabItem>
              </LinkWrapper>
              <LinkWrapper
                decorationHover="none"
                onClick={() => {
                  setOption(SettingOption.Profile);
                }}
              >
                <TabItem
                  breakDirection="row"
                  backgroundHover={AccentTransparentBlack}
                  padding="15px"
                  borderRadius={DefaultBorderRadius}
                  active={option === SettingOption.Profile}
                >
                  <IcoUser
                    size="20px"
                    margin="auto 10px 0px 0px"
                    color={NeutralGray}
                  />
                  <HideMobile>
                    <TextSmall margin="3px 0">{t("LabelMyProfile")}</TextSmall>
                  </HideMobile>
                </TabItem>
              </LinkWrapper>
              {permission?.secondary ? (
                <LinkWrapper
                  decorationHover="none"
                  onClick={() => {
                    setOption(SettingOption.TeamManagement);
                  }}
                >
                  <TabItem
                    breakDirection="row"
                    backgroundHover={AccentTransparentBlack}
                    padding="15px"
                    borderRadius={DefaultBorderRadius}
                    active={option === SettingOption.TeamManagement}
                  >
                    <IcoUsers
                      size="20px"
                      margin="auto 10px 0px 0px"
                      color={NeutralGray}
                    />
                    <HideMobile>
                      <TextSmall margin="3px 0">
                        {t("LabelTeamManagement")}
                      </TextSmall>
                    </HideMobile>
                  </TabItem>
                </LinkWrapper>
              ) : null}
              {permission?.organisation ? (
                <LinkWrapper
                  decorationHover="none"
                  onClick={() => {
                    setOption(SettingOption.Organisation);
                  }}
                >
                  <TabItem
                    breakDirection="row"
                    backgroundHover={AccentTransparentBlack}
                    padding="15px"
                    borderRadius={DefaultBorderRadius}
                    active={option === SettingOption.Organisation}
                  >
                    <IcoHome
                      size="20px"
                      margin="auto 10px 0px 0px"
                      color={NeutralGray}
                    />
                    <HideMobile>
                      <TextSmall margin="3px 0">
                        {t("LabelOrganisationDetails")}
                      </TextSmall>
                    </HideMobile>
                  </TabItem>
                </LinkWrapper>
              ) : null}
              <LinkWrapper
                decorationHover="none"
                onClick={() => {
                  setOption(SettingOption.Security);
                }}
              >
                <TabItem
                  breakDirection="row"
                  backgroundHover={AccentTransparentBlack}
                  padding="15px"
                  borderRadius={DefaultBorderRadius}
                  active={option === SettingOption.Security}
                >
                  <IcoLock
                    size="20px"
                    margin="auto 10px 0px 0px"
                    color={NeutralGray}
                  />
                  <HideMobile>
                    <TextSmall margin="3px 0">{t("LabelSecurity")}</TextSmall>
                  </HideMobile>
                </TabItem>
              </LinkWrapper>
              {!user?.primary_user_id ? (
                <LinkWrapper
                  decorationHover="none"
                  onClick={() => {
                    setOption(SettingOption.Billing);
                  }}
                >
                  <TabItem
                    breakDirection="row"
                    backgroundHover={AccentTransparentBlack}
                    padding="15px"
                    borderRadius={DefaultBorderRadius}
                    active={option === SettingOption.Billing}
                  >
                    <IcoCreditCard
                      size="20px"
                      margin="auto 10px 0px 0px"
                      color={NeutralGray}
                    />
                    <HideMobile>
                      <TextSmall margin="3px 0">
                        {t("LabelBillingInformation")}
                      </TextSmall>
                    </HideMobile>
                  </TabItem>
                </LinkWrapper>
              ) : null}
            </TabsContainer>

            <StyledScollbarContainer
              width="100%"
              height="100%"
              maxHeight="60vh"
              padding="1rem"
              style={{ overflow: "auto", boxSizing: "border-box" }}
            >
              {option === SettingOption.General ? (
                <GeneralSettings />
              ) : option === SettingOption.Profile ? (
                <ProfileSettings />
              ) : option === SettingOption.TeamManagement &&
                permission?.secondary ? (
                <TeamManagementSettings />
              ) : option === SettingOption.Organisation &&
                permission?.organisation ? (
                <OrganisationSettings />
              ) : option === SettingOption.Security ? (
                <SecuritySettings />
              ) : option === SettingOption.Billing && !user?.primary_user_id ? (
                <BillingSettings />
              ) : null}
            </StyledScollbarContainer>
          </SettingContainer>
        </Box>
      </Modal>
    </>
  );
};

export default SettingModal;
