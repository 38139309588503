import {
  ButtonOutlinedDimmed,
  FlexContainer,
  InputOutlined,
  Spacer,
  TextSmall
} from "llane-ui";
import { DefaultBorderRadius, DefaultBoxGap } from "../../../theme";
import { useTranslate } from "../../../contexts/SettingContext";

const OrganisationSettings = () => {
  const { t } = useTranslate();

  return (
    <FlexContainer
      breakDirection="row"
      alignVertical="space-between"
      width="100%"
      style={{ flexWrap: "wrap" }}
      gap={DefaultBoxGap}
    >
      <FlexContainer
        breakDirection="row"
        width="100%"
        style={{ flexWrap: "wrap" }}
        gap={DefaultBoxGap}
      >
        <FlexContainer
          padding="0 10px 0 10px"
          style={{ flex: 1 }}
          minWidth="230px"
          gap={DefaultBoxGap}
        >
          <TextSmall>{t("LabelOrganisationName")}</TextSmall>
          <InputOutlined
            margin="0"
            width="100%"
            placeholder={t("LabelOrganisationName")}
          />
        </FlexContainer>
        <FlexContainer
          padding="0 10px 0 10px"
          style={{ flex: 1 }}
          minWidth="230px"
          gap={DefaultBoxGap}
        >
          <TextSmall>{t("LabelContactPhone")}</TextSmall>
          <InputOutlined
            margin="0"
            width="100%"
            placeholder={t("LabelContactPhone")}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        breakDirection="row"
        width="100%"
        style={{ flexWrap: "wrap" }}
        gap={DefaultBoxGap}
      >
        <FlexContainer
          padding="0 10px 0 10px"
          style={{ flex: 1 }}
          minWidth="230px"
          gap={DefaultBoxGap}
        >
          <TextSmall>{t("LabelPrimaryEmail")}</TextSmall>
          <InputOutlined
            margin="0"
            width="100%"
            placeholder={t("LabelPrimaryEmail")}
          />
        </FlexContainer>
        <FlexContainer
          padding="0 10px 0 10px"
          style={{ flex: 1 }}
          minWidth="230px"
          gap={DefaultBoxGap}
        >
          <TextSmall>{t("LabelHelpEmail")}</TextSmall>
          <InputOutlined
            margin="0"
            width="100%"
            placeholder={t("LabelHelpEmail")}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer breakDirection="row" width="100%" gap={DefaultBoxGap}>
        <FlexContainer width="100%" padding="0 10px 0 10px">
          <TextSmall>{t("LabelAddress")}</TextSmall>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer breakDirection="row" width="100%" padding="0 10px 0 10px">
        <InputOutlined width="100%" placeholder={t("LabelAddress")} />
      </FlexContainer>
      <FlexContainer
        breakDirection="row"
        width="100%"
        gap={DefaultBoxGap}
        style={{ flexWrap: "wrap" }}
        padding="0 10px 0 10px"
      >
        <InputOutlined
          style={{ flex: 1 }}
          minWidth="150px"
          width="100%"
          margin="0"
          placeholder={t("LabelCity")}
        />
        <InputOutlined
          style={{ flex: 1 }}
          minWidth="150px"
          width="100%"
          margin="0"
          placeholder={t("LabelRegion")}
        />
        <InputOutlined
          width="100%"
          margin="0"
          style={{ flex: 1 }}
          minWidth="150px"
          placeholder={t("LabelPostCode")}
        />
      </FlexContainer>
      <Spacer />
      <FlexContainer
        breakDirection="row"
        alignVertical="flex-end"
        gap={DefaultBoxGap}
      >
        <ButtonOutlinedDimmed margin="0" borderRadius={DefaultBorderRadius}>
          {t("LabelCancel")}
        </ButtonOutlinedDimmed>
        <ButtonOutlinedDimmed margin="0" borderRadius={DefaultBorderRadius}>
          {t("LabelSave")}
        </ButtonOutlinedDimmed>
      </FlexContainer>
    </FlexContainer>
  );
};

export default OrganisationSettings;
