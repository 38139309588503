import { useEffect, useMemo, useRef, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { Navigate } from "react-router-dom";
import {
  Button,
  FlexContainer,
  GridContainer,
  HeadingLarge,
  PageWrapper,
  Spacer,
  Text,
  TextSmall
} from "llane-ui";
import { useTranslate } from "../../contexts/SettingContext";
import { DefaultBoxGap } from "../../theme";
import { validateEmail } from "../../utils";
import { toast } from "react-toastify";
import { sendEmail } from "../../api/userApiCall";

function ConfirmEmail() {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [cooldown, setCooldown] = useState(0);

  const timer = useRef<any>();
  const { user } = useUser();

  const cooldownStarted = useMemo(() => cooldown > 0, [cooldown]);

  const handleSendEmail = () => {
    if (cooldownStarted) return;
    const email = user?.email;

    if (!validateEmail(email)) {
      toast.error("Please Login");
    }
    setLoading(true);

    sendEmail({ email })
      .then(() => {
        setLoading(false);
        setCooldown(60);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (cooldownStarted) {
      timer.current = setInterval(() => {
        setCooldown((prev) => (prev > 0 ? prev - 1 : prev));
      }, 1000);
    } else if (timer.current) {
      clearInterval(timer.current);
      timer.current = undefined;
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, [cooldownStarted]);

  if (user && user.email_verified) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageWrapper>
        <Spacer gap="5%" />
        <FlexContainer
          breakDirection="row"
          alignHorizontal="center"
          alignVertical="center"
          gap={DefaultBoxGap}
        >
          <FlexContainer
            padding="10px"
            alignText="center"
            maxWidth="100%"
            width="450px"
            gap="20px"
            style={{ lineHeight: "1.5em" }}
          >
            <HeadingLarge>{t("Confirm Email")}</HeadingLarge>
            <GridContainer gap="10px">
              <Text>
                You need to confirm your email to continue using Arcadia. We
                just sent a confirmation link to:{" "}
              </Text>
              <Text>
                <b>{user?.email}</b>
              </Text>
            </GridContainer>
            <Button
              width="100%"
              onClick={() => handleSendEmail()}
              disabled={cooldownStarted}
            >
              {loading ? `${t("Processing...")}` : `${t("Resend Link")}`}
            </Button>

            {cooldownStarted ? (
              <TextSmall>
                You can send email again after {cooldown} seconds
              </TextSmall>
            ) : null}
          </FlexContainer>
        </FlexContainer>
        <Spacer gap="5%" />
      </PageWrapper>
    </>
  );
}

export default ConfirmEmail;
