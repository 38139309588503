import { createContext, useContext, useRef } from "react";
import { ChatMessage, Prompt } from "../types/prompt";
import { useProviderPrompt } from "../hooks/useProviderPrompt";
import { useCustomCommands } from "../hooks/useCustomCommands";
import { CustomCommand } from "../types/customCommand";
import { useSavedPrompts } from "../hooks/usePrompts";

interface PropmtContextData {
  promptId?: number | null;
  setPromptId: React.Dispatch<React.SetStateAction<number | undefined | null>>;
  messages: ChatMessage[];
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  currentModel: string;
  setCurrentModel: React.Dispatch<React.SetStateAction<string>>;
  customCommands?: CustomCommand[];
  fetchCustomCommands: Function;
  prompts?: Prompt[];
  fetchSavedPrompts: Function;
  abortController?: React.MutableRefObject<AbortController | undefined>;
  isAwaitingResponse: boolean;
  setIsAwaitingResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PromptContext = createContext<PropmtContextData>({
  setPromptId: () => {},
  messages: [],
  setMessages: () => {},
  currentModel: "Arcadia",
  setCurrentModel: () => {},
  fetchCustomCommands: () => {},
  fetchSavedPrompts: () => {},
  isAwaitingResponse: false,
  setIsAwaitingResponse: () => {}
});

export const usePrompt = () => {
  return useContext(PromptContext);
};

export const PromptProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useProviderPrompt();
  const { customCommands, triggerFetch: fetchCustomCommands } =
    useCustomCommands();
  const { prompts, triggerFetch: fetchSavedPrompts } = useSavedPrompts();
  const abortController = useRef<AbortController | undefined>();

  return (
    <PromptContext.Provider
      value={{
        ...data,
        customCommands,
        fetchCustomCommands,
        prompts,
        fetchSavedPrompts,
        abortController
      }}
    >
      {children}
    </PromptContext.Provider>
  );
};
