import {
  Modal,
  Box,
  FlexContainer,
  Heading,
  LinkWrapper,
  IcoX,
  Divider,
  InputOutlined,
  TextSmall,
  ButtonOutlinedDimmed,
  TextExtraSmallError,
  TextExtraSmallSuccess,
  TextExtraSmallInfo,
  TextSmallError,
  NumberInput,
  ToggleButton,
  Dropdown,
  DropdownLink,
  HeadingSmall,
  Text,
  Spacer,
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../../theme";
import { useTranslate } from "../../contexts/SettingContext";
import { useEffect, useMemo, useState } from "react";
import { updateSubUser, updateSubUserPassword } from "../../api/userApiCall";
import { useUser } from "../../contexts/UserContext";
import { testPassword, validateEmail } from "../../utils";
import { useSubUserDetail } from "../../hooks/useSubUsers";
import { useFineTunings } from "../../hooks/useFineTunings";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const SubUserEditModal = ({
  user_id,
  open,
  handleClose,
  triggerFetch
}: {
  user_id: number | undefined;
  open: boolean;
  handleClose: Function;
  triggerFetch: Function;
}) => {
  const { t } = useTranslate();
  const [saving, setSaving] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);

  const { subUser } = useSubUserDetail(user_id);

  const [email, setEmail] = useState("");
  const [Arcadia, setArcadia] = useState(0);
  const [ArcadiaImage, setArcadiaImage] = useState(0);
  const [GPT35, setGPT35] = useState(0);
  const [ArcadiaLite, setArcadiaLite] = useState(0);
  const [GPT40, setGPT40] = useState(0);
  const [GPT40o, setGPT40o] = useState(0);
  const [Claude, setClaude] = useState(0);
  const [Gemini, setGemini] = useState(0);
  const [fineTuningLimit, setFineTuningLimit] = useState(0);
  const [customCommand, setCustomCommand] = useState(0);
  const [saveOrUpdate, setSaveOrUpdate] = useState(false);
  const [fineTuning, setFineTuning] = useState<any>();
  // const [model, setModel] = useState<any>();

  const [password, setPassword] = useState("");

  const [error, setError] = useState<any>();
  const { accessToken } = useUser();

  const { fineTunings } = useFineTunings();

  // const models = useMemo(() => {
  //   if (!permission) return [];
  //   else {
  //     return Object.keys(AIEngineEndpoints)
  //       .map((key) => {
  //         const item = (AIEngineEndpoints as any)[key];
  //         if (permission[item.model]) return item;
  //         else return null;
  //       })
  //       .filter((item) => Boolean(item));
  //   }
  // }, [permission]);

  const emailError = useMemo(() => {
    if (email === "" || validateEmail(email)) return null;
    else return "No valid email";
  }, [email]);

  const passwordStrength = useMemo(() => {
    if (password === "") return 0;
    if (password.length < 8) {
      return 1;
    }
    return testPassword(password);
  }, [password]);

  const handleSubmit = () => {
    const payload = {
      user_id: subUser.user_id,
      email,
      ArcadiaLite,
      GPT35,
      GPT40,
      GPT40o,
      Arcadia,
      ArcadiaImage,
      Claude,
      Gemini,
      saveOrUpdate,
      fineTuning: fineTuningLimit,
      customCommand,
      defaultFineTuning: fineTuning?.fine_tuning_id
      // model: model?.Name
    };
    if (!validateEmail(email)) {
      setError("Please check inputs.");
      return;
    }
    setSaving(true);
    updateSubUser(payload, accessToken)
      .catch(() => {})
      .finally(() => {
        setSaving(false);
        triggerFetch?.();
        handleClose();
      });
  };

  const handleChangePassword = () => {
    const payload = {
      user_id: subUser.user_id,
      password
    };
    setSavingPassword(true);
    updateSubUserPassword(payload, accessToken)
      .catch(() => {})
      .finally(() => {
        setSavingPassword(false);
      });
  };

  useEffect(() => {
    setEmail("");
    setPassword("");
  }, [open]);

  useEffect(() => {
    if (subUser) {
      setEmail(subUser.email);
      const permission = subUser.permission;
      const fineTuning = subUser.fineTuning;
      if (permission) {
        const {
          ArcadiaLite,
          GPT35,
          GPT40,
          GPT40o,
          Arcadia,
          ArcadiaImage,
          Claude,
          Gemini,
          saveOrUpdate,
          fineTuning,
          customCommand
        } = permission;

        setArcadiaLite(ArcadiaLite);
        setGPT35(GPT35);
        setGPT40(GPT40);
        setGPT40o(GPT40o);
        setArcadia(Arcadia);
        setArcadiaImage(ArcadiaImage);
        setClaude(Claude);
        setGemini(Gemini);
        setSaveOrUpdate(saveOrUpdate);
        setFineTuningLimit(fineTuning);
        setCustomCommand(customCommand);
      }
      if (fineTuning) {
        setFineTuning(fineTuning);
      }
    }
  }, [subUser]);

  return (
    <Modal
      width="auto"
      open={open}
      handleClose={() => {
        handleClose();
      }}
    >
      <Box
        height="auto"
        width="750px"
        minHeight="60vh"
        maxHeight="90vh"
        overflow="hidden"
      >
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>Edit Secondary Account</Heading>
          <LinkWrapper
            onClick={(e: any) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <Box padding="0">
              <IcoX margin="0" />
            </Box>
          </LinkWrapper>
        </FlexContainer>
        <Divider />
        {!subUser ? (
          <LoaderPaddingWrapper>
            <DotsLoader>
              <Dot delay="0s" />
              <Dot delay="0.1s" />
              <Dot delay="0.2s" />
            </DotsLoader>
          </LoaderPaddingWrapper>
        ) : (
          
          <FlexContainer
            breakDirection="column"
            gap={DefaultBoxGap}
            style={{ overflow: "auto" }}
          >
            <HeadingSmall>General Settings</HeadingSmall>
            <FlexContainer breakDirection="row">
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("LabelEmail")}</TextSmall>
                <InputOutlined
                  margin="0"
                  width="100%"
                  value={email}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                  placeholder={t("LabelEmail")}
                />
                <TextExtraSmallError margin="0 0 0 10px">
                  {emailError}
                </TextExtraSmallError>
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("LabelFineTuningProfiles")}</TextSmall>
                <Dropdown
                  title={
                    fineTuning
                      ? `${fineTuning.fine_tuning_name}`
                      : "Fine Tuning"
                  }
                >
                  {fineTunings?.map((item, index) => (
                    <DropdownLink
                      key={item.fine_tuning_id ?? index}
                      onClick={() => {
                        setFineTuning(item);
                      }}
                    >{`${item?.fine_tuning_name ?? ""}`}</DropdownLink>
                  ))}
                </Dropdown>
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 0 }}
                minWidth="200px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("Can this user save or update chat prompts?")}</TextSmall>
                <ToggleButton
                  toggled={saveOrUpdate}
                  onClick={() => {
                    setSaveOrUpdate(!saveOrUpdate);
                  }}
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer breakDirection="row">
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 0 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("LabelNewPassword")}</TextSmall>
                <InputOutlined
                  margin="0"
                  width="100%"
                  type="password"
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                  placeholder={t("LabelPassword")}
                />
                {passwordStrength === 1 ? (
                  <TextExtraSmallError margin="0 0 0 10px">
                    Your password is too weak.
                  </TextExtraSmallError>
                ) : passwordStrength === 2 ? (
                  <TextExtraSmallError margin="0 0 0 10px">
                    Try adding numbers and symbols.
                  </TextExtraSmallError>
                ) : passwordStrength === 3 ? (
                  <TextExtraSmallInfo margin="0 0 0 10px">
                    Your password is acceptable.
                  </TextExtraSmallInfo>
                ) : passwordStrength > 3 ? (
                  <TextExtraSmallSuccess margin="0 0 0 10px">
                    You are using a strong password.
                  </TextExtraSmallSuccess>
                ) : null}
              </FlexContainer>
              <FlexContainer
                alignVertical="flex-end"
                alignHorizontal="flex-start"
                width="fit-content"
              >
                <ButtonOutlinedDimmed
                  margin="0"
                  borderRadius={DefaultBorderRadius}
                  onClick={() => {
                    handleChangePassword();
                  }}
                >
                  {savingPassword ? (
                    <DotsLoader>
                      <Dot delay="0s" />
                      <Dot delay="0.1s" />
                      <Dot delay="0.2s" />
                    </DotsLoader>
                  ) : (
                    t("Change")
                  )}
                </ButtonOutlinedDimmed>
              </FlexContainer>
              <FlexContainer>
              <TextSmallError>{error}</TextSmallError>
            </FlexContainer>
            </FlexContainer>

            <Divider />
            <HeadingSmall>Usage Limits</HeadingSmall>
            <Text>Set how many messages this account can send to each AI model per hour. The message count will be deducted from your main account's usage limit.</Text>
            <Spacer gap="0" />
            <FlexContainer
              breakDirection="row"
              gap={DefaultBoxGap}
              style={{ flexWrap: "wrap" }}
            >
              
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("ArcadiaLite")}</TextSmall>
                <NumberInput
                  margin="0"
                  width="100%"
                  type="text"
                  value={ArcadiaLite}
                  onChange={(value: number) => {
                    setArcadiaLite(value);
                  }}
                  pattern="[0-9]*"
                  min={0}
                  max={40}
                  placeholder={t("ArcadiaLite")}
                />
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("GPT3.5")}</TextSmall>
                <NumberInput
                  margin="0"
                  width="100%"
                  type="text"
                  value={GPT35}
                  onChange={(value: number) => {
                    setGPT35(value);
                  }}
                  pattern="[0-9]*"
                  min={0}
                  max={40}
                  placeholder={t("GPT3.5")}
                />
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("Claude")}</TextSmall>
                <NumberInput
                  margin="0"
                  width="100%"
                  type="text"
                  value={Claude}
                  onChange={(value: number) => {
                    setClaude(value);
                  }}
                  pattern="[0-9]*"
                  min={0}
                  max={40}
                  placeholder={t("Claude")}
                />
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("Gemini")}</TextSmall>
                <NumberInput
                  margin="0"
                  width="100%"
                  type="text"
                  value={Gemini}
                  onChange={(value: number) => {
                    setGemini(value);
                  }}
                  pattern="[0-9]*"
                  min={0}
                  max={40}
                  placeholder={t("Gemini")}
                />
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("Arcadia")}</TextSmall>
                <NumberInput
                  margin="0"
                  width="100%"
                  type="text"
                  value={Arcadia}
                  onChange={(value: number) => {
                    setArcadia(value);
                  }}
                  pattern="[0-9]*"
                  min={0}
                  max={40}
                  placeholder={t("Arcadia")}
                />
              </FlexContainer>
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("Arcadia Image")}</TextSmall>
                <NumberInput
                  margin="0"
                  width="100%"
                  type="text"
                  value={ArcadiaImage}
                  onChange={(value: number) => {
                    setArcadiaImage(value);
                  }}
                  pattern="[0-9]*"
                  min={0}
                  max={40}
                  placeholder={t("Arcadia Image")}
                />
              </FlexContainer>
            </FlexContainer>
           
            <FlexContainer
              breakDirection="row"
              gap={DefaultBoxGap}
              style={{ flexWrap: "wrap" }}
            >
              
            </FlexContainer>

            <Divider />

            <FlexContainer
              breakDirection="row"
              alignVertical="flex-end"
              gap={DefaultBoxGap}
            >
              <ButtonOutlinedDimmed
                margin="0"
                borderRadius={DefaultBorderRadius}
                onClick={() => handleClose()}
              >
                {t("LabelCancel")}
              </ButtonOutlinedDimmed>
              <ButtonOutlinedDimmed
                margin="0"
                borderRadius={DefaultBorderRadius}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {saving ? (
                  <DotsLoader>
                    <Dot delay="0s" />
                    <Dot delay="0.1s" />
                    <Dot delay="0.2s" />
                  </DotsLoader>
                ) : (
                  t("LabelSave")
                )}
              </ButtonOutlinedDimmed>
            </FlexContainer>
          </FlexContainer>
        )}
      </Box>
    </Modal>
  );
};

export default SubUserEditModal;
