import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import {
  ComponentBackground,
  SubmenuLinkHoverBackground,
  TextColor,
  IcoChevronDown,
  Transparent,
  HeaderLinkHoverBackground
} from "..";
import {
  BorderThickness,
  DefaultBoxShadow,
  HeaderHeight,
  SubmenuContainerBorderRadius,
  SubmenuContainerPadding,
  SubmenuFontSize,
  SubmenuLinkPadding,
  SubmenuPadding
} from "../BaseThemeStyle";

interface ComponentProps {
  padding?: string;
  color?: string;
  backgroundHover?: string;
  background?: string;
}

export const SubmenuLink = styled.a<ComponentProps>`
  display: block;
  background: ${(props) => props.background || Transparent};
  padding: ${(props) => props.padding || SubmenuLinkPadding};
  color: ${(props) => props.color || TextColor};
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.backgroundHover || SubmenuLinkHoverBackground};
  }
`;

const SubmenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SubmenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  width: auto;
  min-width: 260px;

  @media (min-width: 769px) {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    top: calc(100%);
    margin: 0;
    background: ${ComponentBackground};
    border-radius: 0 ${SubmenuContainerBorderRadius}
      ${SubmenuContainerBorderRadius} ${SubmenuContainerBorderRadius};
    border: ${BorderThickness} solid ${Transparent};
    box-shadow: ${DefaultBoxShadow};
    padding: ${SubmenuContainerPadding};
    width: 100%;
  }

  @media (max-width: 768px) {
    background: transparent !important;
  }
`;

const SubmenuToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Transparent};
  border: none;
  color: ${TextColor};
  font-size: ${SubmenuFontSize};
  padding: ${SubmenuPadding};
  cursor: pointer;
  text-align: center;
  width: fit-content;
  min-height: ${HeaderHeight};
  box-sizing: border-box;
  &:hover {
    color: ${TextColor};
    text-decoration: none;
    background: ${HeaderLinkHoverBackground};
  }
  @media (max-width: 768px) {
    background: transparent !important;
    width: 100%;
    justify-content: flex-start;
    min-height: inherit;
  }
`;

interface SubmenuProps {
  children: ReactNode;
  title?: string;
}

export const Submenu: React.FC<SubmenuProps> = ({
  children,
  title = "Submenu Title"
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SubmenuWrapper>
      <SubmenuToggle
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        <span style={{ flexGrow: 1 }} />
        <span>
          <IcoChevronDown size="0.9rem" margin="auto auto -3px auto" />
        </span>
      </SubmenuToggle>
      {isOpen && (
        <SubmenuContainer
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children}
        </SubmenuContainer>
      )}
    </SubmenuWrapper>
  );
};
