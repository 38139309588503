import { useMemo, useState } from "react";
import {
  ButtonOutlined,
  IcoCheck,
  FlexContainer,
  Spacer,
  Heading,
  TextSmall,
  Text
} from "llane-ui";
import { PlanItem } from "../../theme";
import { TierDetails } from "../../constants";
import CommonModal from "../Modals/Confirmations/CommonModal";
import { getDateStr } from "../../utils";
import { useUser } from "../../contexts/UserContext";

const tiers = [
  {
    label: "Free",
    price: "Free",
    price_secondary: "Not Available",
    value: 1
  },
  {
    label: "Premium",
    price: "USD 9.7/mo",
    price_secondary: "Not Available",
    value: 2
  },
  {
    label: "Professional",
    price: "USD 19/mo",
    price_secondary: "Not Available",
    value: 3
  },
  {
    label: "Enterprise",
    price: "USD 49/mo",
    price_secondary: "$5/mo per Account",
    value: 4
  }
];

const Plan = ({
  tier,
  subscribe,
  currentTier,
  fetching,
  currentSubscription
}: any) => {
  const [openModal, setOpenModal] = useState(false);

  const { user } = useUser();

  const isCurrentSubscription = useMemo(() => {
    if (currentTier.value === tier.value) return true;
    else return false;
  }, [currentTier, tier]);

  const levelUp = useMemo(() => {
    if (currentTier.value < tier.value) return true;
    else return false;
  }, [currentTier, tier]);

  const modalTitle = useMemo(() => {
    const userTier = user.tier_id;
    const userExpire = getDateStr(user.expire_date);
    if (userTier > 1 && userTier > tier.value) {
      return `Your ${
        tiers[userTier - 1].label
      } account subscription will remain active until ${userExpire}. Your plan will be downgraded to ${
        tier.label
      } thereafter.`;
    } else if (currentTier.value < tier.value) {
      return `Your plan will be upgraded to ${tier.label}.`;
    } else {
      let date = "next payment date.";
      if (currentSubscription) {
        date = getDateStr(currentSubscription.next_payment_date);
      }
      return `Your ${currentTier.label} account subscription will remain active until ${date}. Your plan will be downgraded to ${tier.label} thereafter.`;
    }
  }, [user, tier, currentTier, currentSubscription]);

  // main function
  const handleClick = async () => {
    setOpenModal(true);
  };

  const callback = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await subscribe(tier.value);
      } catch (error) {
        console.log(error);
      } finally {
      }
    });
  };

  return (
    <>
      <PlanItem>
        <FlexContainer
          breakDirection="row"
          gap={"5px"}
          alignHorizontal="center"
          style={{ flexWrap: "wrap" }}
        >
          {tier.icon} <Heading>{tier.label}</Heading>
          <Text style={{ whiteSpace: "nowrap" }}>{tier.price}</Text>
        </FlexContainer>
        <FlexContainer gap="3px">
          {(TierDetails as any)[tier.value as any]?.map((detail: any) => (
            <FlexContainer>
              <TextSmall style={{ lineHeight: "1.5em" }}>
                <IcoCheck size="15px" margin="0 10px 0 0" />
                {detail}
              </TextSmall>
            </FlexContainer>
          ))}
        </FlexContainer>
        {!fetching && (
          <FlexContainer margin="auto 0 0 0">
            <Spacer />
            <ButtonOutlined
              width="100%"
              disabled={isCurrentSubscription}
              onClick={() => handleClick()}
            >
              {isCurrentSubscription
                ? "Current Subscription"
                : levelUp
                ? "Upgrade"
                : "Downgrade"}
            </ButtonOutlined>
          </FlexContainer>
        )}
      </PlanItem>
      <CommonModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        title="Confirm"
        content={modalTitle}
        callback={callback}
      />
    </>
  );
};

export default Plan;
