import React, {
  useState,
  createContext,
  useEffect,
  FunctionComponent,
  isValidElement
} from "react";
import styled, { css, keyframes } from "styled-components";
import { useLocation } from "react-router-dom";
import {
  AppBackground,
  Box,
  HeaderDesktop,
  HeaderMobile,
  HeaderSection,
  IcoMenu,
  IcoX
} from "llane-ui";

interface MenuContentProps {
  open: boolean;
  background?: string;
}

interface MenuMobileProps {
  index: number;
}

interface MobileMenuContentProps {
  children: React.ReactNode;
}

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MobileMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 1rem;
  position: relative;
`;

const MenuContent = styled.div<MenuContentProps>`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: ${(props) => (props.open ? "0" : "-100%")};
  padding: 1rem;
  padding-top: 3.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.background || AppBackground};
  overflow: auto;
  box-sizing: border-box;
  z-index: 2;
  transition: left 0.3s ease-in-out;
`;

const MenuItem = styled.div<MenuMobileProps>`
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 0.75rem;
  display: flex;
  width: 100%;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  animation: ${(props) =>
    css`
      ${slideDown} 0.3s ease forwards ${props.index * 0.1}s
    `};

  &:hover {
    background-color: rgba(255, 255, 255, 0.025);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.07);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

interface ContextInterface {
  open: boolean;
  setOpen: Function;
}

export const MobileMenuContext = createContext<ContextInterface>({
  open: false,
  setOpen: () => {}
});

const MobileMenu: FunctionComponent<MobileMenuContentProps> = ({
  children
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <MobileMenuContext.Provider value={{ open, setOpen }}>
      <MobileMenuWrapper>
        <div onClick={() => setOpen(!open)}>
          <IcoMenu size="42px" style={{ margin: "-5px 0 0 0" }} />
        </div>
        <MenuContent open={open}>
          <CloseIcon onClick={() => setOpen(false)}>
            <Box padding="5px" margin="-3px 0 5px 0">
              <IcoX margin="0" />
            </Box>
          </CloseIcon>
          {React.Children.map(children, (child, index) =>
            isValidElement(child) && child?.props?.id !== "logo-wrapper" ? (
              <MenuItem key={index} index={index}>
                {child}
              </MenuItem>
            ) : null
          )}
        </MenuContent>
      </MobileMenuWrapper>
    </MobileMenuContext.Provider>
  );
};

const ResponsiveHeaderLinks = ({ children }: { children: any }) => {
  return (
    <>
      <HeaderDesktop>
        <HeaderSection>{children}</HeaderSection>
      </HeaderDesktop>
      <HeaderMobile>
        <MobileMenu>{children}</MobileMenu>
      </HeaderMobile>
    </>
  );
};

export default ResponsiveHeaderLinks;
