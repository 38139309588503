import styled from "styled-components";
import { FlexContainer } from "llane-ui";
import { AccentTransparentBlack } from "../../../theme";

export const SettingContainer = styled(FlexContainer)`
  flex-direction: row;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TabsContainer = styled(FlexContainer)`
  flex-direction: column;
  min-height: 60px;
  @media (max-width: 768px) {
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
  }
`;

export const TabItem = styled(FlexContainer)<{ active?: boolean }>`
  white-space: nowrap;
  background: ${(props) => (props.active ? AccentTransparentBlack : undefined)};

  @media (max-width: 768px) {
    & > svg {
      margin: 0;
    }
  }
`;
