import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoX,
  InputOutlined,
  LinkWrapper,
  Modal,
  Spacer,
  Text,
  TextAreaOutlined
} from "llane-ui";
import { useEffect, useState } from "react";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../../theme";
import { useTranslate } from "../../contexts/SettingContext";
import { useUser } from "../../contexts/UserContext";
import {
  fetchCustomCommandById,
  updateCustomCommand
} from "../../api/customCommandApiCall";
import { CustomCommand } from "../../types/customCommand";

const CustomCommandsEditModal = ({
  custom_command_id,
  open,
  handleClose,
  callback
}: {
  custom_command_id: number | undefined;
  open: boolean;
  handleClose: Function;
  callback: Function;
}) => {
  const { t } = useTranslate();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { accessToken } = useUser();

  const [command, setCommand] = useState("");
  const [commandReturn, setCommandReturn] = useState("");

  const handleSubmit = () => {
    if (!custom_command_id) return;
    const payload: CustomCommand = {
      custom_command_id,
      command,
      command_return: commandReturn
    };

    setSaving(true);

    updateCustomCommand(payload, accessToken)
      .then(() => {
        callback();
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
        handleClose();
      });
  };

  useEffect(() => {
    if (custom_command_id && accessToken) {
      setLoading(true);
      fetchCustomCommandById(custom_command_id, accessToken)
        .then((response: any) => {
          if (
            response &&
            response.custom_command_id &&
            response.command &&
            response.command_return
          ) {
            const command = response.command;
            setCommand(command);
            const command_return = response.command_return;
            setCommandReturn(command_return);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [custom_command_id, accessToken]);

  return (
    <>
      <Modal width="auto" open={open} handleClose={() => handleClose()}>
        <Box
          height="auto"
          width="750px"
          minHeight="60vh"
          maxHeight="90vh"
          overflow="hidden"
        >
          <FlexContainer
            style={{
              overflow: "hidden"
            }}
          >
            <FlexContainer alignVertical="space-between" breakDirection="row">
              <Heading>{t("LabelCustomCommand")}</Heading>
              <LinkWrapper onClick={() => handleClose()}>
                <Box padding="0">
                  <IcoX margin="0" />
                </Box>
              </LinkWrapper>
            </FlexContainer>
            <Divider gap="3px" />
            {loading ? (
              <LoaderPaddingWrapper>
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              </LoaderPaddingWrapper>
            ) : (
              <FlexContainer style={{ flex: 1, overflow: "hidden" }}>
                <FlexContainer>
                  <Text style={{ lineHeight: "1.5em" }}>
                    {t("LabelCustomCommandCreationInstruction")}
                  </Text>
                </FlexContainer>
                <Spacer gap="5px" />
                <FlexContainer>
                  <InputOutlined
                    width="100%"
                    style={{ boxSizing: "border-box" }}
                    placeholder={t("LabelTypeCommandHere")}
                    value={command}
                    onChange={(e: any) => {
                      setCommand(e.target.value);
                    }}
                  />
                </FlexContainer>
                <Spacer gap="5px" />
                <FlexContainer
                  breakDirection="column"
                  width="100%"
                  style={{ flex: 1, overflow: "hidden" }}
                  gap={DefaultBoxGap}
                >
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flex: 1, overflow: "auto" }}
                  >
                    <TextAreaOutlined
                      width="100%"
                      rows={15}
                      placeholder={t("LabelCommandReturnStatement")}
                      value={commandReturn}
                      onChange={(e: any) => {
                        setCommandReturn(e.target.value);
                      }}
                    />
                  </FlexContainer>
                  <Spacer gap="0" />
                  <FlexContainer
                    breakDirection="row"
                    alignVertical="flex-end"
                    gap={DefaultBoxGap}
                  >
                    <ButtonOutlinedDimmed
                      margin="0"
                      borderRadius={DefaultBorderRadius}
                      onClick={() => handleClose()}
                    >
                      {t("LabelCancel")}
                    </ButtonOutlinedDimmed>
                    <ButtonOutlinedDimmed
                      margin="0"
                      borderRadius={DefaultBorderRadius}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {saving ? (
                        <DotsLoader>
                          <Dot delay="0s" />
                          <Dot delay="0.1s" />
                          <Dot delay="0.2s" />
                        </DotsLoader>
                      ) : (
                        t("LabelSaveClose")
                      )}
                    </ButtonOutlinedDimmed>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>
        </Box>
      </Modal>
    </>
  );
};

export default CustomCommandsEditModal;
