import { useEffect, useMemo, useState } from "react";
import { ChatMessage } from "../types/prompt";
import { useUser } from "../contexts/UserContext";
import { AIEngineEndpoints } from "../constants";

export const useProviderPrompt = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [currentModel, setCurrentModel] = useState<any>();
  const [promptId, setPromptId] = useState<number | undefined | null>();
  const [isAwaitingResponse, setIsAwaitingResponse] = useState<boolean>(false);

  const { permission } = useUser();

  const models = useMemo(() => {
    if (!permission) return [];
    else {
      return Object.keys(AIEngineEndpoints)
        .map((key) => {
          const item = (AIEngineEndpoints as any)[key];
          if (permission[item.model]) return item;
          else return null;
        })
        .filter((item) => Boolean(item));
    }
  }, [permission]);

  useEffect(() => {
    if (models.length) {
      setCurrentModel(models[0].model);
    }
  }, [models]);

  return {
    messages,
    setMessages,
    currentModel,
    setCurrentModel,
    promptId,
    setPromptId,
    isAwaitingResponse,
    setIsAwaitingResponse
  };
};
