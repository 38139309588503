import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoX,
  LinkWrapper,
  Modal,
  Text
} from "llane-ui";
import { DefaultBorderRadius, DefaultBoxGap } from "../../../theme";
import { useModal } from "../../../contexts/ModalContext";
import { usePrompt } from "../../../contexts/PromptContext";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslate } from "../../../contexts/SettingContext";

const NewPromptModal = () => {
  const { isOpen, handleClose } = useModal("newPrompt");
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const { setMessages, setPromptId, abortController } = usePrompt();

  const handleYesClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (abortController && abortController.current) {
        abortController.current.abort();
      }
      handleClose(); // Close the modal first
      if (location.pathname === "/prompt") {
        setMessages([]);
        setPromptId(null);
      } else {
        navigate("/prompt"); // Navigate without using React Router
      }
    },
    [handleClose, setMessages, setPromptId, navigate, location, abortController]
  );

  return (
    <Modal width="auto" open={isOpen} handleClose={() => handleClose()}>
      <Box height="auto" overflow="hidden">
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>{t("LabelNewPrompt")}</Heading>
          <LinkWrapper
            onClick={(e: any) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <Box padding="0">
              <IcoX margin="0" />
            </Box>
          </LinkWrapper>
        </FlexContainer>
        <Divider gap="3px" />
        <FlexContainer>
          <Text style={{ lineHeight: "1.5em" }}>
            {t("LabelNewPromptConfirmation")}
          </Text>
        </FlexContainer>
        <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
          <FlexContainer
            breakDirection="row"
            alignVertical="flex-end"
            gap={DefaultBoxGap}
          >
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => handleClose()}
            >
              {t("LabelNo")}
            </ButtonOutlinedDimmed>
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={handleYesClick}
            >
              {t("LabelYes")}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        </FlexContainer>
      </Box>
    </Modal>
  );
};

export default NewPromptModal;
