import {
  Modal,
  Box,
  FlexContainer,
  Heading,
  LinkWrapper,
  IcoX,
  Divider
} from "llane-ui";
import CheckoutForm from "../CheckoutForm";
import { useModal } from "../../contexts/ModalContext";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const SubscriptionModal = () => {
  const { isOpen, handleClose } = useModal("subscription");
  return (
    <Modal
      width="auto"
      open={isOpen}
      handleClose={() => {
        handleClose();
      }}
    >
      <Box
        height="auto"
        width="1280px"
        maxWidth="90vw"
        minHeight="auto"
        maxHeight="90vh"
        overflow="hidden"
      >
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>Manage Subscription Plan</Heading>
          <LinkWrapper
            onClick={(e: any) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <Box padding="0">
              <IcoX margin="0" />
            </Box>
          </LinkWrapper>
        </FlexContainer>
        <Divider gap="0" />
        <CheckoutForm />
      </Box>
    </Modal>
  );
};

export default SubscriptionModal;
