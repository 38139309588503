import { RouterProvider } from "react-router-dom";
import router from "./router";
import { UserProvider } from "./contexts/UserContext";
import { PromptProvider } from "./contexts/PromptContext";
import { ModalProvider } from "./contexts/ModalContext";
import { SettingProvider } from "./contexts/SettingContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastProvider } from "./contexts/ToastContext";

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <SettingProvider>
          <PromptProvider>
            <ToastProvider>
              <RouterProvider router={router} />
            </ToastProvider>
          </PromptProvider>
        </SettingProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
