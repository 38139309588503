import { URLSearchParams } from "url";
import { BackendEndpoint } from "../constants";
import { toast } from "react-toastify";

export const getApiCall = (
  url: string,
  accessToken = "",
  params?: URLSearchParams
): Promise<any> => {
  return fetch(BackendEndpoint + url + (params ?? ""), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bear " + accessToken
    }
  }).then(async (res) => {
    if (res.status === 200) {
      return res.json();
    }
    const data = await res.json();
    if (data.message) {
      if (data.message === "jwt expired") {
        toast.error("Your session has expired, please login again");
      }
      throw new Error(data.message);
    } else {
      throw new Error(res.statusText);
    }
  });
};

export const postApiCall = (
  url: string,
  payload: any,
  token = "",
  signal?: AbortSignal | null
): Promise<any> => {
  return fetch(BackendEndpoint + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bear " + token
    },
    signal,
    body: JSON.stringify(payload)
  }).then(async (res) => {
    if (signal && signal?.aborted) {
      return;
    }
    if (res.status === 200) {
      return res.json();
    }
    const data = await res.json();
    if (data.message) {
      if (data.message === "jwt expired") {
        toast.error("Your session has expired, please login again");
      }
      throw new Error(data.message);
    } else {
      throw new Error(res.statusText);
    }
  });
};

export const postApiCallWithStream = (
  url: string,
  payload: any,
  callback: Function,
  token = "",
  signal?: AbortSignal | null
): Promise<any> => {
  return fetch(BackendEndpoint + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bear " + token
    },
    body: JSON.stringify(payload),
    signal
  }).then(async (res) => {
    if (signal && signal?.aborted) {
      return;
    }
    if (res.status !== 200) {
      const data = await res.json();
      if (data.message) {
        if (data.message === "jwt expired") {
          toast.error("Your session has expired, please login again");
        }
        throw data;
      } else {
        throw new Error(res.statusText);
      }
    }
    return new Promise<void>((resolve, reject) => {
      if (signal && signal?.aborted) {
        return resolve();
      }
      if (!res.body) return reject();
      // Get the reader from the stream
      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();

      let message = "";
      // Define a function to read each chunk
      const readChunk = () => {
        // Read a chunk from the reader
        reader
          .read()
          .then(({ value, done }: any) => {
            // Check if the stream is done
            if (done) {
              // Log a message
              // Return from the function
              return resolve();
            }
            // Convert the chunk value to a string
            const lines = value.split("\n");

            // Initialize variables to store parsed data
            // let eventName = "";
            let jsonData = "";

            // Loop through each line
            lines.forEach((line: string) => {
              // Trim the line to remove any leading or trailing whitespace
              line = line.trim();

              // Check if the line starts with 'event:'
              if (line.startsWith("event:")) {
                // Extract the event name
                // eventName = line.substring(6).trim();
              } else if (line.startsWith("data:")) {
                // Extract the JSON data
                jsonData = line.substring(5).trim();
                try {
                  const data = JSON.parse(jsonData);
                  message += data.token;
                  // Log the chunk string
                  callback(message);
                } catch (err: any) {
                  // console.log(err.message, jsonData);
                }
              }
            });

            // Read the next chunk
            readChunk();
          })
          .catch((error) => {
            // Log the error
            console.error(error);
            reject();
          });
      };
      // Start reading the first chunk
      readChunk();
    });
  });
};

export const deleteApiCall = (url: string, token = ""): Promise<any> => {
  return fetch(BackendEndpoint + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bear " + token
    }
  }).then(async (res) => {
    if (res.status === 200) {
      return res.json();
    }
    const data = await res.json();
    if (data.message) {
      if (data.message === "jwt expired") {
        toast.error("Your session has expired, please login again");
      }
      throw new Error(data.message);
    } else {
      throw new Error(res.statusText);
    }
  });
};
