import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppWrapper, FlexContainer, PageWrapper, Spacer, Text } from "llane-ui";
// import { useTranslate } from "../../contexts/SettingContext";
import { DefaultBoxGap, Spinner } from "../../theme";
import { verifyEmail } from "../../api/userApiCall";

export function Verify() {
  // const { t } = useTranslate();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const link = searchParams.get("link");
    if (link && link !== "") {
      verifyEmail(link)
        .then(() => {
          setLoading(false);
          navigate("/login");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [searchParams, navigate]);

  return (
    <AppWrapper>
      <PageWrapper>
        <Spacer gap="5%" />
        <FlexContainer
          breakDirection="row"
          alignHorizontal="center"
          alignVertical="center"
          gap={DefaultBoxGap}
          style={{ flex: 1 }}
        >
          {loading && (
            <>
              <Text>Verifying</Text>
              <Spinner />
            </>
          )}
        </FlexContainer>
        <Spacer gap="5%" />
      </PageWrapper>
    </AppWrapper>
  );
}

export default Verify;
