import { useCallback, useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";
import {
  fetchUserFineTune,
  saveUserFineTune
} from "../api/userfineTuneApiCall";
import { useModal } from "../contexts/ModalContext";

export const useUserFineTuning = () => {
  const [userFineTuning, setFineTuning] = useState<any | undefined>();
  const { user, accessToken } = useUser();
  const { handleOpen, handleClose } = useModal("loading");

  const triggerFetch = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (user && user.user_id && accessToken) {
        fetchUserFineTune(accessToken)
          .then((data) => {
            setFineTuning(data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  }, [user, setFineTuning, accessToken]);

  const saveUserFineTuning = useCallback(
    (fine_tuning_id: number, state = true) => {
      if (user && user.user_id && accessToken) {
        handleOpen();
        saveUserFineTune(fine_tuning_id, state, accessToken)
          .then(() => {
            return triggerFetch();
          })
          .catch(() => {})
          .finally(() => {
            handleClose();
          });
      }
    },
    [user, triggerFetch, handleOpen, handleClose, accessToken]
  );

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { userFineTuning, triggerFetch, saveUserFineTuning };
};
