import {
  ButtonOutlinedDimmed,
  FlexContainer,
  Heading,
  HeadingLarge,
  IcoDownload,
  IcoMessageCircle,
  IcoPlay,
  IcoShare,
  IcoTrash2,
  PageWrapper,
  ResponsiveBox,
  Spacer,
  Text
} from "llane-ui";
import {
  AccentTransparentBlack,
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper,
  PageWrapperMargin,
  ResponsiveHeadContainer
} from "../theme";
import { useUser } from "../contexts/UserContext";
import { ChatMessage, Prompt } from "../types/prompt";
import { usePrompt } from "../contexts/PromptContext";
import { useNavigate } from "react-router-dom";
import { handleSavePrompt } from "../utils/saveToFile";
import { removePrompt } from "../api/promptApiCall";
import CommonModal from "../components/Modals/Confirmations/CommonModal";
import { useCallback, useMemo, useState } from "react";
import { useTranslate } from "../contexts/SettingContext";
import Search from "../components/Search";
import { useSearch } from "../hooks/useSearch";
import { SelectList } from "../constants";
import Sort from "../components/Sort";
import { shortenString } from "../utils/string";

export function SavedPrompts() {
  const { t } = useTranslate();

  const { accessToken, permission } = useUser();

  const [selected, setSelected] = useState(SelectList[0]);

  const { prompts, fetchSavedPrompts: triggerFetch } = usePrompt();

  const { setMessages, setPromptId } = usePrompt();
  const [promptIdToRemove, setPromptIdToRemove] = useState<number | undefined>(
    undefined
  );

  const navigate = useNavigate();

  const handleOpenPrompt = (promptData: Prompt) => {
    const { prompt_id, prompt } = promptData;
    setPromptId(prompt_id);
    if (prompt?.length > 0) {
      setMessages(prompt);
    } else {
      setMessages([]);
    }
    navigate(`/prompt?prompt_id=${prompt_id}`);
  };

  const handleSavePromptFunc = (promptData: Prompt) => {
    const { prompt } = promptData;

    if (prompt?.length > 0) {
      handleSavePrompt(prompt);
    }
  };

  const handleRemovePrompt = (prompt_id: number) => {
    setPromptIdToRemove(prompt_id);
  };

  const removePromptCallback = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (promptIdToRemove)
        removePrompt(promptIdToRemove, accessToken)
          .then(() => {
            triggerFetch();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
    });
  }, [promptIdToRemove, triggerFetch, accessToken]);

  const search = useSearch();

  const searchedPrompts = useMemo(() => {
    if (!prompts) return undefined;
    let _prompts = [...prompts];
    if (search) {
      _prompts = _prompts?.filter((prompt) => {
        const messages = prompt.prompt;
        const prompt_id = prompt.prompt_id;

        if (prompt_id.toString().includes(search)) return true;

        for (let index = 0; index < messages.length; index++) {
          const element = messages[index];
          const content = element.content;
          if (
            content?.includes(search) ||
            content?.toLowerCase()?.includes(search)
          ) {
            return true;
          }
        }
        return false;
      });
    }

    if (selected !== "Default") {
      _prompts.sort((a: Prompt, b: Prompt) => {
        if (selected === "Date, Newest First") {
          const aDate = new Date(a.last_prompt_date);
          const bDate = new Date(b.last_prompt_date);

          return aDate > bDate ? -1 : aDate === bDate ? 0 : 1;
        } else if (selected === "Date, Oldest First") {
          const aDate = new Date(a.last_prompt_date);
          const bDate = new Date(b.last_prompt_date);

          return aDate > bDate ? 1 : aDate === bDate ? 0 : -1;
        } else if (selected === "Alphabetical, A-Z") {
          return a.prompt?.[0].content?.toLowerCase() >
            b.prompt?.[0].content?.toLowerCase()
            ? 1
            : a.prompt?.[0].content?.toLowerCase() ===
              b.prompt?.[0].content?.toLowerCase()
            ? 0
            : -1;
        } else if (selected === "Alphabetical, Z-A") {
          return a.prompt?.[0].content?.toLowerCase() >
            b.prompt?.[0].content?.toLowerCase()
            ? -1
            : a.prompt?.[0].content?.toLowerCase() ===
              b.prompt?.[0].content?.toLowerCase()
            ? 0
            : 1;
        } else return 0;
      });
    }
    return _prompts;
  }, [search, prompts, selected]);

  const sharePost = async (promptData: Prompt) => {
    const { prompt } = promptData;

    const text = prompt.reduce((value: string, message: ChatMessage) => {
      const currrentTxt = message.subject + ": " + message.content + "\n";
      return value.concat(currrentTxt);
    }, "");

    if (navigator.share) {
      try {
        await navigator.share({ text });
      } catch (err) {
        console.error("Error sharing:", err);
      }
    } else {
      navigator.clipboard.writeText(text).then(
        () => {
          alert("Link copied to clipboard!");
        },
        (err) => {
          console.error("Error copying link:", err);
        }
      );
    }
  };

  if (!permission?.saveOrUpdate) {
    return null;
  }

  return (
    <PageWrapper margin={PageWrapperMargin}>
      <ResponsiveBox style={{ overflow: "hidden" }} height="auto">
        <FlexContainer
          breakDirection="column"
          gap={DefaultBoxGap}
          style={{ overflow: "hidden" }}
          height="100%"
        >
          <ResponsiveHeadContainer
            breakDirection="row"
            alignHorizontal="center"
            alignVertical="center"
            gap={DefaultBoxGap}
          >
            <FlexContainer style={{ marginRight: "auto" }}>
              <HeadingLarge>{t("LabelSavedPrompts")}</HeadingLarge>
            </FlexContainer>
            <FlexContainer
              breakDirection="row"
              alignHorizontal="center"
              style={{ flex: 1, flexWrap: "wrap" }}
              gap={DefaultBoxGap}
              width="100%"
            >
              <FlexContainer style={{ flex: 1 }}>
                <Search />
              </FlexContainer>
              <FlexContainer
                alignHorizontal="center"
                alignVertical="flex-end"
                breakDirection="row"
                style={{ marginLeft: "auto" }}
              >
                <Sort selected={selected} setSelected={setSelected} />
              </FlexContainer>
            </FlexContainer>
          </ResponsiveHeadContainer>
          <Spacer gap="0.2rem" />
          <FlexContainer
            padding="10px"
            gap={DefaultBoxGap}
            style={{ overflow: "auto" }}
            height="100%"
          >
            {prompts === undefined ? (
              <LoaderPaddingWrapper>
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              </LoaderPaddingWrapper>
            ) : searchedPrompts && searchedPrompts.length > 0 ? (
              <>
                {searchedPrompts?.map((prompt: Prompt) => {
                  return (
                    <FlexContainer
                      key={prompt.prompt_id}
                      background={AccentTransparentBlack}
                      width="100%"
                      padding="1rem"
                      breakDirection="row"
                      borderRadius={DefaultBorderRadius}
                      gap={DefaultBoxGap}
                      style={{ flexWrap: "wrap" }}
                    >
                      <FlexContainer
                        width="fit-content"
                        padding="1rem"
                        breakDirection="row"
                        borderRadius={DefaultBorderRadius}
                        gap={DefaultBoxGap}
                        style={{ flexWrap: "wrap" }}
                      >
                        <FlexContainer
                          alignHorizontal="left"
                          alignVertical="center"
                        >
                          <IcoMessageCircle size="32px" />
                        </FlexContainer>
                        <FlexContainer
                          breakDirection="column"
                          alignHorizontal="left"
                          alignVertical="center"
                          width="fit-content"
                          style={{ flex: 1, minWidth: 200 }}
                        >
                          <Heading>
                            {prompt.title
                              ? prompt.title
                              : `Prompt #${prompt.prompt_id}`}
                          </Heading>
                          <Spacer gap="0.1rem" />
                          <Text>
                            {prompt.description
                              ? shortenString(prompt.description, 100)
                              : shortenString(
                                  prompt.prompt?.[0]?.content ?? "",
                                  100
                                )}
                          </Text>
                          <Spacer gap="5px" />
                          <Text style={{ lineHeight: "1.5em" }}>
                            <b>
                              {t("LabelCreatedOn")}:{"  "}
                            </b>
                            {new Date(prompt.creation_date).toDateString()} |{" "}
                            <b>
                              {t("LabelLastModifiedOn")}:{"  "}
                            </b>
                            {new Date(prompt.last_prompt_date).toDateString()}
                          </Text>
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer
                        breakDirection="row"
                        borderRadius={DefaultBorderRadius}
                        gap={DefaultBoxGap}
                        style={{ marginLeft: "auto" }}
                      >
                        <FlexContainer>
                          <ButtonOutlinedDimmed
                            title={t("LabelOpenPrompt")}
                            onClick={() => handleOpenPrompt(prompt)}
                          >
                            <IcoPlay size="16px" margin="auto" />
                          </ButtonOutlinedDimmed>
                        </FlexContainer>
                        <FlexContainer>
                          <ButtonOutlinedDimmed
                            title={t("LabelDownload")}
                            onClick={() => handleSavePromptFunc(prompt)}
                          >
                            <IcoDownload size="16px" margin="auto" />
                          </ButtonOutlinedDimmed>
                        </FlexContainer>
                        <FlexContainer>
                          <ButtonOutlinedDimmed
                            title={t("LabelShare")}
                            onClick={() => sharePost(prompt)}
                          >
                            <IcoShare size="16px" margin="auto" />
                          </ButtonOutlinedDimmed>
                        </FlexContainer>
                        <FlexContainer>
                          <ButtonOutlinedDimmed
                            title={t("LabelDelete")}
                            onClick={() => handleRemovePrompt(prompt.prompt_id)}
                          >
                            <IcoTrash2 size="16px" margin="auto" />
                          </ButtonOutlinedDimmed>
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  );
                })}
              </>
            ) : (
              <>{t("LabelNoSavedPrompts")}</>
            )}
          </FlexContainer>
        </FlexContainer>
      </ResponsiveBox>
      <CommonModal
        open={Boolean(promptIdToRemove)}
        title="Delete Prompt"
        content="Are you sure you want to delete this prompt?"
        callback={removePromptCallback}
        handleClose={() => setPromptIdToRemove(undefined)}
      />
    </PageWrapper>
  );
}

export default SavedPrompts;
