import { useCallback, useEffect, useState } from "react";
import { getSubUerDetail, getSubUsers } from "../api/userApiCall";
import { useUser } from "../contexts/UserContext";

const useSubUsers = () => {
  const [subUsers, setSubUsers] = useState<any>();
  const { accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    getSubUsers(accessToken)
      .then((response) => {
        setSubUsers(response);
      })
      .catch((err) => {});
  }, [accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { subUsers, triggerFetch };
};

export const useSubUserDetail = (user_id: number | undefined) => {
  const [subUser, setSubUser] = useState<any>();
  const { accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    if (user_id) {
      setSubUser(undefined);
      getSubUerDetail(user_id, accessToken)
        .then((response) => {
          setSubUser(response);
        })
        .catch((err) => {});
    }
  }, [user_id, accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { subUser, triggerFetch };
};

export default useSubUsers;
