import { getApiCall } from ".";
import { BackendEndpoint } from "../constants";

export const fetchSubscription = (token = "") => {
  const url = `/subscription`;
  return getApiCall(url, token);
};

export const fetchSubscriptionHistory = (token = "") => {
  const url = `/subscription/history`;
  return getApiCall(url, token);
};

export const createSubscription = (payload: any, token = "") => {
  return fetch(`${BackendEndpoint}/subscription/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bear " + token
    },
    body: JSON.stringify(payload)
  });
};

export const manageSubscription = (token = "") => {
  return fetch(`${BackendEndpoint}/subscription/manage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bear " + token
    }
  });
};
