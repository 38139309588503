import { useEffect, useMemo, useRef } from "react";
import { useUserSetting } from "./SettingContext";
import { Id, ToastContainer, ToastItem, toast } from "react-toastify";

const useToastWithTheme = () => {
  const { setting } = useUserSetting();
  const toastIds = useRef<Id[]>([]);

  const currentTheme = useMemo(() => {
    if (setting?.theme) return setting.theme;
    else return "dark";
  }, [setting]);

  useEffect(() => {
    toastIds.current?.forEach((toastId: Id) => {
      toast.update(toastId, {
        theme: currentTheme
      });
    });
  }, [currentTheme]);

  useEffect(() => {
    const unsubscribe = toast.onChange((payload: ToastItem) => {
      switch (payload.status) {
        case "added":
          toastIds.current.push(payload.id);
          break;
        case "updated":
          // toast updated
          break;
        case "removed":
          if (toastIds.current.includes(payload.id)) {
            toastIds.current = toastIds.current.filter(
              (toastId) => toastId !== payload.id
            );
          }
          break;
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return { currentTheme };
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentTheme } = useToastWithTheme();

  return (
    <>
      {children}
      <ToastContainer theme={currentTheme} />
    </>
  );
};
