import { useCallback, useState, useMemo } from "react";
import {
  Box,
  ButtonOutlinedAlt,
  ButtonOutlinedDimmed,
  Container,
  Dropdown,
  DropdownLink,
  FlexContainer,
  HeaderDesktop,
  HeadingLarge,
  IcoCheck,
  IcoClipboard,
  IcoCopy,
  IcoFeather,
  IcoFile,
  IcoFolder,
  IcoPrinter,
  IcoSave,
  IcoShare,
  Transparent
} from "llane-ui";
import { DividerGray } from "../theme";
import { ChatMessage } from "../types/prompt";
import { copyToClipboard, pasteFromClipboard } from "../utils/clipboard";
import { usePrompt } from "../contexts/PromptContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AIEngineEndpoints } from "../constants";
import { useModal } from "../contexts/ModalContext";
import { usePrintPrompt } from "../hooks/usePrintPrompt";
import { useTranslate } from "../contexts/SettingContext";
import { useUser } from "../contexts/UserContext";

interface ToolbarProps {
  messages: ChatMessage[];
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
}

export function ToolBar({ setUserInput }: ToolbarProps) {
  const { t } = useTranslate();
  const { messages, currentModel, setCurrentModel } = usePrompt();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const printPrompt = usePrintPrompt();
  const { permission } = useUser();
  const isEmpty = useMemo(() => messages.length === 0, [messages]);

  const models = useMemo(() => {
    if (!permission) return [];
    else {
      return Object.keys(AIEngineEndpoints)
        .map((key) => {
          const item = (AIEngineEndpoints as any)[key];
          if (permission[item.model]) return item;
          else return null;
        })
        .filter((item) => Boolean(item));
    }
  }, [permission]);

  const fileIsOpen = useMemo(() => {
    return location.pathname === "/prompt";
  }, [location]);

  const isNewPrompt = useMemo(() => {
    const promptId = searchParams.get("prompt_id");
    if (promptId && !Number.isNaN(promptId)) {
      return false;
    } else {
      return true;
    }
  }, [searchParams]);

  const { toggleModal: toggleNewPromptModal } = useModal("newPrompt");
  const { toggleModal: toggleOpenPromptModal } = useModal("openPrompt");
  const { toggleModal: toggleSavePromptModal } = useModal("savePrompt");
  const { toggleModal: toggleUpdatePromptModal } = useModal("updatePrompt");
  const { toggleModal: toggleSummaryModal } = useModal("summary");

  const [copied, setCopied] = useState(false);
  const [pasted, setPasted] = useState(false);

  const handleNewPromptRefresh = () => {
    if (location.pathname === "/prompt") {
      window.location.reload(); // Reloads the page
    } else {
      navigate("/prompt"); // Navigates to /prompt
    }
  };

  const handlePaste = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      pasteFromClipboard().then((text: string | null) => {
        if (text) {
          setUserInput((prev: string) => {
            return prev.concat(text);
          });
          setPasted(true);
          setTimeout(() => {
            setPasted(false);
          }, 1000);
        }
      });
    },
    [setUserInput]
  );

  const handleCopy = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const text = messages.reduce((value: string, message: ChatMessage) => {
        const currrentTxt = message.subject + ": " + message.content + "\n";
        return value.concat(currrentTxt);
      }, "");
      copyToClipboard(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    },
    [messages]
  );

  const sharePost = async () => {
    const text = messages.reduce((value: string, message: ChatMessage) => {
      const currrentTxt = message.subject + ": " + message.content + "\n";
      return value.concat(currrentTxt);
    }, "");
    if (navigator.share) {
      try {
        await navigator.share({ text: text });
      } catch (err) {
        console.error(`${t("LabelErrorSharing")}`, err);
      }
    } else {
      navigator.clipboard.writeText(text).then(
        () => {
          alert(`${t("LabelLinkCopied")}`);
        },
        (err) => {
          console.error(`${t("LabelLinkCopyError")}`, err);
        }
      );
    }
  };

  return (
    <HeaderDesktop>
      <Box
        borderRadius="30px 30px 0 0"
        width="100%"
        height="auto"
        padding="1rem 0.5rem 0 0.5rem"
      >
        <FlexContainer
          breakDirection="row"
          gap="10px"
          alignHorizontal="center"
          alignVertical="center"
        >
          <Container>
            {!isEmpty && fileIsOpen ? (
              <ButtonOutlinedDimmed
                title={t("LabelNew")}
                onClick={(e: any) => {
                  e.preventDefault();
                  toggleNewPromptModal();
                }}
              >
                <IcoFile size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            ) : (
              <ButtonOutlinedDimmed
                title={t("LabelNew")}
                onClick={handleNewPromptRefresh}
              >
                <IcoFile size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            )}
          </Container>
          <Container>
            <ButtonOutlinedDimmed
              title={t("LabelOpen")}
              onClick={(e: any) => {
                e.preventDefault();
                toggleOpenPromptModal();
              }}
            >
              <IcoFolder size="16px" margin="0" />
            </ButtonOutlinedDimmed>
          </Container>

          <Container>
            {!isEmpty && fileIsOpen ? (
              <ButtonOutlinedDimmed
                title={t("LabelSave")}
                onClick={(e: any) => {
                  e.preventDefault();
                  if (isNewPrompt) {
                    toggleSavePromptModal();
                  } else {
                    toggleUpdatePromptModal();
                  }
                }}
              >
                <IcoSave size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            ) : (
              <ButtonOutlinedDimmed
                title={t("LabelSave")}
                disabled={true}
                backgroundHover={Transparent}
                style={{ cursor: "default" }}
              >
                <IcoSave size="16px" margin="0" color={DividerGray} />
              </ButtonOutlinedDimmed>
            )}
          </Container>
          <Container>
            <HeadingLarge color={DividerGray}>|</HeadingLarge>
          </Container>
          <Container>
            {pasted ? (
              <ButtonOutlinedDimmed>
                <IcoCheck size="16px" margin="0" color="green" />
              </ButtonOutlinedDimmed>
            ) : (
              <ButtonOutlinedDimmed
                title={t("LabelPaste")}
                onClick={handlePaste}
              >
                <IcoClipboard size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            )}
          </Container>
          <Container>
            {copied ? (
              <ButtonOutlinedDimmed>
                <IcoCheck size="16px" margin="0" color="green" />
              </ButtonOutlinedDimmed>
            ) : (
              <ButtonOutlinedDimmed title={t("LabelCopy")} onClick={handleCopy}>
                <IcoCopy size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            )}
          </Container>
          <Container>
            <HeadingLarge color={DividerGray}>|</HeadingLarge>
          </Container>
          <Container>
            {!isEmpty && fileIsOpen ? (
              <ButtonOutlinedDimmed
                title={t("LabelPrint")}
                onClick={printPrompt}
              >
                <IcoPrinter size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            ) : (
              <ButtonOutlinedDimmed
                title={t("LabelPrint")}
                disabled={true}
                backgroundHover={Transparent}
                style={{ cursor: "default" }}
              >
                <IcoPrinter size="16px" margin="0" color={DividerGray} />
              </ButtonOutlinedDimmed>
            )}
          </Container>
          <Container>
            {!isEmpty && fileIsOpen ? (
              <ButtonOutlinedDimmed
                title={t("LabelShare")}
                onClick={() => sharePost()}
              >
                <IcoShare size="16px" margin="0" />
              </ButtonOutlinedDimmed>
            ) : (
              <ButtonOutlinedDimmed
                title={t("LabelShare")}
                disabled={true}
                borderColor={DividerGray}
                backgroundHover={Transparent}
                style={{ cursor: "default" }}
              >
                <IcoShare size="16px" margin="0" color={DividerGray} />
              </ButtonOutlinedDimmed>
            )}
          </Container>
          <Container>
            <HeadingLarge color={DividerGray}>|</HeadingLarge>
          </Container>
          <Container>
            <Dropdown
              title={
                AIEngineEndpoints[
                  currentModel as keyof typeof AIEngineEndpoints
                ]?.Name ?? t("No Model")
              }
            >
              {models?.map(({ Name, model }) => {
                return (
                  <DropdownLink
                    key={Name}
                    onClick={() => {
                      setCurrentModel(model);
                    }}
                  >
                    {Name}
                  </DropdownLink>
                );
              })}
            </Dropdown>
          </Container>
          <Container>
            <HeadingLarge color={DividerGray}>|</HeadingLarge>
          </Container>
          <Container>
            <ButtonOutlinedAlt
              title={t("LabelSummarise")}
              onClick={(e: any) => {
                e.preventDefault();
                toggleSummaryModal();
              }}
            >
              <IcoFeather size="16px" margin="0" />
            </ButtonOutlinedAlt>
          </Container>
        </FlexContainer>
      </Box>
    </HeaderDesktop>
  );
}
