export const BackendEndpoint = "https://backend.arcadia.neptureai.com/api";
export const ImageStorage = "https://backend.arcadia.neptureai.com/images";
//export const BackendEndpoint = "http://localhost:5000/api";
//export const ImageStorage = "http://localhost:5000/images";
export const ContextFactor = `10`;

export const AIEngineEndpoints = {
  Arcadia: {
    Name: "Arcadia™",
    model: "Arcadia",
    extra: true
  },
  ArcadiaLite: {
    Name: "Arcadia Lite™",
    model: "ArcadiaLite",
    extra: false
  },
  Claude: {
    Name: "Claude (Anthropic)",
    model: "Claude",
    extra: false
  },
  GPT35: {
    Name: "ChatGPT (OpenAI)",
    model: "GPT35",
    extra: false
  },
  Gemini: {
    Name: "Gemini (Google)",
    model: "Gemini",
    extra: false
  }
};

export const AIImageGenerationEndpoints = {
  ArcadiaImage: {
    Name: "Arcadia Image Generator"
  }
};

export const Languages = [
  {
    label: "English (EN/UK)",
    value: "en-uk"
  },
  {
    label: "العربية (AR)",
    value: "ar"
  },
  {
    label: "中文 (ZH/CN)",
    value: "zh-cn"
  },
  {
    label: "Deutsch (DE)",
    value: "de"
  },
  {
    label: "Español (ES)",
    value: "es"
  },
  {
    label: "Français (FR)",
    value: "fr"
  },
  {
    label: "हिंदी (HI)",
    value: "hi"
  },
  {
    label: "Italian (IT)",
    value: "it"
  },
  {
    label: "Português (PT/BR)",
    value: "pt-br"
  },
  {
    label: "Русский (RU)",
    value: "ru"
  }
];

export const themes = [
  {
    label: "LabelDark",
    value: "dark"
  },
  {
    label: "LabelLight",
    value: "light"
  }
];

export const CommandFailSentence = `Sorry but I could not find any command with the specified parameters. You may want to try a different command.`;

export const SelectList = [
  "Default",
  "Date, Newest First",
  "Date, Oldest First",
  "Alphabetical, A-Z",
  "Alphabetical, Z-A"
];

export const TierDetails = {
  1: ["Arcadia Lite™", "Save Prompts", "Restricted Usage Limits"],
  2: [
    "All Features from Free",
    "Open AI Chat GPT",
    "Save Prompts",
    "Save/Load Prompts to/from Files",
    "Fair Usage Limits"
  ],
  3: [
    "All Features from Premium",
    "Google Gemini",
    "Anthropic Claude",
    "Custom Commands",
    "Up to 1 Fine Tuning Profile",
    "Up to 5 Custom Commands",
    "Generous Usage Limits"
  ],
  4: [
    "Everything from Professional",
    "All AI Models, Including Arcadia™",
    "Image Generation",
    "Team Management Tools",
    "Up to 5 Secondary Accounts*",
    "Unlimited Custom Commands",
    "Unlimited Fine Tuning Profiles",
    "Enterprise-level Usage Limits"
  ]
};

export const RECAPTCHA_KEY = "6LcCVP8pAAAAAEsxWWG-vjVPBqvr4CPlQC40VzG5";
