import {
  ButtonAlt,
  Divider,
  Dropdown,
  DropdownLink,
  FlexContainer,
  Text
} from "llane-ui";
import { DefaultBorderRadius, DefaultBoxGap } from "../../../theme";
import { useTranslate, useUserSetting } from "../../../contexts/SettingContext";
import { useCallback, useMemo, useState } from "react";
import { Languages, themes } from "../../../constants";
import { useUser } from "../../../contexts/UserContext";
import { removeAllPrompt } from "../../../api/promptApiCall";
import CommonModal from "../Confirmations/CommonModal";
import { usePrompt } from "../../../contexts/PromptContext";

const GeneralSettings = () => {
  const { t } = useTranslate();

  const { setting, update } = useUserSetting();

  const { fetchSavedPrompts } = usePrompt();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const { accessToken, logout } = useUser();

  const language = useMemo(() => {
    if (setting?.language) {
      const languageItem = Languages.find(
        ({ value }) => value === setting?.language
      );
      if (languageItem) return languageItem;
    } else {
      return null;
    }
  }, [setting]);

  const theme = useMemo(() => {
    if (setting?.theme) {
      const themeItem = themes.find(({ value }) => value === setting.theme);
      if (themeItem) return themeItem;
    } else {
      return null;
    }
  }, [setting]);

  const updateLanguage = useCallback(
    (item: string) => {
      if (setting.language !== item) update({ ...setting, language: item });
    },
    [setting, update]
  );

  const updateTheme = useCallback(
    (item: string) => {
      if (setting.theme !== item) update({ ...setting, theme: item });
    },
    [setting, update]
  );

  const removeCallback = useCallback(() => {
    return removeAllPrompt(accessToken)
      .then(() => {
        fetchSavedPrompts();
      })
      .catch(() => {});
  }, [accessToken, fetchSavedPrompts]);

  return (
    <FlexContainer
      breakDirection="column"
      width="100%"
      height="100%"
      minHeight="40vh"
    >
      <FlexContainer
        alignVertical="space-between"
        alignHorizontal="center"
        breakDirection="row"
        width="100%"
        gap={DefaultBoxGap}
        style={{ flexWrap: "wrap" }}
      >
        <Text>{t("LabelDefaultLanguage")}</Text>
        <FlexContainer style={{ marginLeft: "auto" }}>
          <Dropdown
            title={language?.label ?? "Select language"}
            maxHeight="250px"
          >
            {Languages.map((item) => (
              <DropdownLink
                key={item.value}
                onClick={() => {
                  updateLanguage(item.value);
                }}
              >
                {item.label}
              </DropdownLink>
            ))}
          </Dropdown>
        </FlexContainer>
      </FlexContainer>

      <Divider />

      <FlexContainer
        alignVertical="space-between"
        alignHorizontal="center"
        breakDirection="row"
        width="100%"
        gap={DefaultBoxGap}
        style={{ flexWrap: "wrap" }}
      >
        <Text>{t("LabelDefaultTheme")}</Text>
        <FlexContainer style={{ marginLeft: "auto" }}>
        <Dropdown title={t(theme?.label ?? "Dark")}>
          {themes.map((item) => (
            <DropdownLink
              key={item.value}
              onClick={() => {
                updateTheme(item.value);
              }}
            >
              {t(item.label)}
            </DropdownLink>
          ))}
        </Dropdown>
        </FlexContainer>
      </FlexContainer>

      <Divider />

      <FlexContainer
        alignVertical="space-between"
        alignHorizontal="center"
        breakDirection="row"
        width="100%"
      >
        <Text>{t("LabelDeleteAllPrompts")}</Text>
        <ButtonAlt
          margin="0"
          borderRadius={DefaultBorderRadius}
          background="#db3333"
          backgroundHover="#db3333c9"
          onClick={() => {
            setOpenDeleteModal(true);
          }}
        >
          {t("LabelDeleteAll")}
        </ButtonAlt>
      </FlexContainer>

      <CommonModal
        open={openDeleteModal}
        title="Delete All Saved Prompts"
        content="Are you sure you want delete all saved prompts? This action is permanent and irreversible."
        callback={removeCallback}
        handleClose={() => setOpenDeleteModal(false)}
      />

      <Divider />

      <FlexContainer
        alignVertical="space-between"
        alignHorizontal="center"
        breakDirection="row"
        width="100%"
      >
        <Text>{t("LabelLogout")}</Text>
        <ButtonAlt
          margin="0"
          borderRadius={DefaultBorderRadius}
          background="#0E8B6D"
          backgroundHover="#0D8065"
          onClick={() => {
            setOpenLogoutModal(true);
          }}
        >
          {t("LabelLogout")}
        </ButtonAlt>
        <CommonModal
          open={openLogoutModal}
          title="Logout"
          content="Are you sure you want to logout from this device?"
          callback={logout}
          handleClose={() => setOpenLogoutModal(false)}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default GeneralSettings;
