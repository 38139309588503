import { useStripe } from "@stripe/react-stripe-js";
import { useRef } from "react";
import { useUser } from "../contexts/UserContext";
import { FlexContainer, IcoBox } from "llane-ui";
import { Dot, DotsLoader, LoaderPaddingWrapper, PlanContainer } from "../theme";
import { createSubscription } from "../api/subscriptionApiCall";
import Plan from "./Plan";
import { useSubscription } from "../hooks/useSubscription";

const tiers = [
  {
    label: "Free",
    price: "Free",
    value: 1,
    icon: <IcoBox size="24px" margin="0" />
  },
  {
    label: "Premium",
    price: "USD 9.7/mo",
    value: 2,
    icon: <IcoBox size="24px" margin="0" />
  },
  {
    label: "Professional",
    price: "USD 19/mo",
    value: 3,
    icon: <IcoBox size="24px" margin="0" />
  },
  {
    label: "Enterprise",
    price: "USD 49/mo",
    value: 4,
    icon: <IcoBox size="24px" margin="0" />
  }
];

function CheckoutForm() {
  // collect data from the user
  const { accessToken, user, triggerFetch } = useUser();

  const { subscription, triggerFetch: fetchSubscription } = useSubscription();

  const loading = useRef(false);

  // stripe items
  const stripe = useStripe();

  // main function
  const subscribe = (tier_id: number) => {
    return new Promise<void>(async (resolve, reject) => {
      if (loading.current) {
        reject();
      }
      loading.current = true;
      // create a payment method
      // call the backend to create subscription
      await createSubscription(
        { tier: tier_id, subscription_user_id: user.user_id },
        accessToken
      )
        .then(async (response) => {
          triggerFetch();
          if (response.status === 409) {
            const data = await response.json();
            if (data && data.redirectUrl) {
              window.open(data.redirectUrl, "_blank");
            }
          } else {
            const session = await response.json();
            stripe?.redirectToCheckout({
              sessionId: session.id
            });
          }
          setTimeout(() => {
            triggerFetch();
            fetchSubscription();
          }, 5000);
        })
        .catch(() => {});

      loading.current = false;
      resolve();
    });
  };

  return (
    <FlexContainer
      breakDirection="column"
      gap="15px"
      style={{ overflow: "auto" }}
    >
      {subscription === undefined ? (
        <LoaderPaddingWrapper>
          <DotsLoader>
            <Dot delay="0s" />
            <Dot delay="0.1s" />
            <Dot delay="0.2s" />
          </DotsLoader>
        </LoaderPaddingWrapper>
      ) : (
        <PlanContainer>
          {tiers.map((tier) => (
            <Plan
              tier={tier}
              subscribe={subscribe}
              currentTier={
                tiers[subscription?.tier_id ? subscription?.tier_id - 1 : 0]
              }
              currentSubscription={subscription}
            />
          ))}
        </PlanContainer>
      )}
    </FlexContainer>
  );
}

export default CheckoutForm;
