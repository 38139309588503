import { getApiCall, postApiCall } from ".";

export const fetchUserFineTune = (token = "") => {
  const url = `/user-fine-tuning`;
  return getApiCall(url, token);
};

export const saveUserFineTune = (
  fine_tuning_id: number,
  state = true,
  token = ""
) => {
  const url = `/user-fine-tuning`;
  return postApiCall(url, { fine_tuning_id, state }, token);
};
