import { useUser } from "../contexts/UserContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppWrapper } from "llane-ui";

export const AuthLayout = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  if (user && user.email_verified) {
    navigate("/prompt");
  }

  if (user && !user.email_verified && location.pathname !== "/confirm") {
    navigate("/confirm");
  }

  if (
    !user &&
    location.pathname !== "/login" &&
    location.pathname !== "/signup"
  ) {
    navigate("/login");
  }

  return (
    <AppWrapper alignHorizontal="center" alignVertical="top">
      <Outlet />
    </AppWrapper>
  );
};
