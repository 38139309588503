export const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function testPassword(pwString: string) {
  var strength = 0;

  strength += /[A-Z]+/.test(pwString) ? 1 : 0;
  strength += /[a-z]+/.test(pwString) ? 1 : 0;
  strength += /[0-9]+/.test(pwString) ? 1 : 0;
  strength += /[\W]+/.test(pwString) ? 1 : 0;

  return strength;
}

export function getDateStr(timestamp: number) {
  const time = new Date(timestamp);

  return time.toDateString().slice(4, 16);
}
