import {
  Modal,
  Box,
  FlexContainer,
  Heading,
  LinkWrapper,
  IcoX,
  Divider,
  InputOutlined,
  TextSmall,
  ButtonOutlinedDimmed,
  TextExtraSmallError,
  TextExtraSmallSuccess,
  TextExtraSmallInfo,
  TextSmallError,
  Dropdown,
  DropdownLink
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader
} from "../../theme";
import { useTranslate } from "../../contexts/SettingContext";
import { useMemo, useState } from "react";
import { createSubUser } from "../../api/userApiCall";
import { useUser } from "../../contexts/UserContext";
import { testPassword, validateEmail } from "../../utils";
import { useFineTunings } from "../../hooks/useFineTunings";
// import { AIEngineEndpoints } from "../../constants";
import { useStripe } from "@stripe/react-stripe-js";
import CommonModal from "./Confirmations/CommonModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const NewSubUserModal = ({
  open,
  handleClose,
  triggerFetch
}: {
  open: boolean;
  handleClose: Function;
  triggerFetch: Function;
}) => {
  const { t } = useTranslate();
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fineTuning, setFineTuning] = useState<any>();
  // const [model, setModel] = useState<any>();
  const [openConfirm, setOpenConfirm] = useState(false);

  const stripe = useStripe();

  const [error, setError] = useState<any>();
  const { accessToken, /* permission */ } = useUser();

  const { fineTunings } = useFineTunings();

  /* const models = useMemo(() => {
    if (!permission) return [];
    else {
      return Object.keys(AIEngineEndpoints)
        .map((key) => {
          const item = (AIEngineEndpoints as any)[key];
          if (permission[item.model]) return item;
          else return null;
        })
        .filter((item) => Boolean(item));
    }
  }, [permission]); */

  const emailError = useMemo(() => {
    if (email === "" || validateEmail(email)) return null;
    else return "No valid email";
  }, [email]);

  const passwordStrength = useMemo(() => {
    if (password === "") return 0;
    if (password.length < 8) {
      return 1;
    }
    return testPassword(password);
  }, [password]);

  const confirmError = useMemo(() => {
    if (
      !confirmPassword ||
      confirmPassword === "" ||
      confirmPassword === password
    )
      return null;
    else return "Password mismatch";
  }, [confirmPassword, password]);

  const disabled = useMemo(() => {
    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      emailError ||
      confirmError ||
      passwordStrength < 3
    )
      return true;
    else return false;
  }, [
    email,
    password,
    confirmPassword,
    emailError,
    confirmError,
    passwordStrength
  ]);

  const handleSubmit = async () => {
    if (disabled) return;
    const payload = {
      email,
      password,
      fineTuning: fineTuning?.fine_tuning_id,
      // model: model?.Name
    };
    if (!validateEmail(email) || passwordStrength < 3) {
      setError("Please check inputs.");
      return;
    }
    setSaving(true);
    createSubUser(payload, accessToken)
      .then((response) => {
        const { stripeSessionId } = response;
        stripe?.redirectToCheckout({
          sessionId: stripeSessionId
        });
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
        triggerFetch();
        handleClose();
      });
  };

  return (
    <>
      <Modal
        width="auto"
        open={open}
        handleClose={() => {
          handleClose();
        }}
      >
        <Box height="auto" width="750px" maxHeight="90vh">
          <FlexContainer alignVertical="space-between" breakDirection="row">
            <Heading>Create New User</Heading>
            <LinkWrapper
              onClick={(e: any) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            </LinkWrapper>
          </FlexContainer>
          <Divider />
          <FlexContainer breakDirection="column" gap={DefaultBoxGap}>
            <FlexContainer breakDirection="row">
            <FlexContainer
              padding="0 10px 0 10px"
              style={{ flex: 1 }}
              minWidth="230px"
              gap={DefaultBoxGap}
            >
              <TextSmall>{t("LabelEmail")}</TextSmall>
              <InputOutlined
                margin="0"
                width="100%"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("LabelEmail")}
              />
              <TextExtraSmallError margin="0 0 0 10px">
                {emailError}
              </TextExtraSmallError>
            </FlexContainer>
            <FlexContainer
              breakDirection="row"
              gap={DefaultBoxGap}
              style={{ flexWrap: "wrap" }}
            >
              <FlexContainer
                padding="0 10px 0 10px"
                style={{ flex: 1 }}
                minWidth="230px"
                gap={DefaultBoxGap}
              >
                <TextSmall>{t("LabelFineTuningProfile")}</TextSmall>
                <Dropdown
                  title={
                    fineTuning
                      ? `${fineTuning.fine_tuning_name}`
                      : "Fine Tuning"
                  }
                >
                  {fineTunings?.map((item, index) => (
                    <DropdownLink
                      key={item.fine_tuning_id ?? index}
                      onClick={() => {
                        setFineTuning(item);
                      }}
                    >{`${item?.fine_tuning_name ?? ""}`}</DropdownLink>
                  ))}
                </Dropdown>
              </FlexContainer>
            </FlexContainer>
            </FlexContainer>
            <FlexContainer
              padding="0 10px 0 10px"
              style={{ flex: 1 }}
              minWidth="230px"
              gap={DefaultBoxGap}
            >
              <TextSmall>{t("LabelPassword")}</TextSmall>
              <InputOutlined
                margin="0"
                width="100%"
                type="password"
                value={password}
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                placeholder={t("LabelPassword")}
              />
              {passwordStrength === 1 ? (
                <TextExtraSmallError margin="0 0 0 10px">
                  Your password is too weak.
                </TextExtraSmallError>
              ) : passwordStrength === 2 ? (
                <TextExtraSmallError margin="0 0 0 10px">
                  Try adding numbers and symbols.
                </TextExtraSmallError>
              ) : passwordStrength === 3 ? (
                <TextExtraSmallInfo margin="0 0 0 10px">
                  Your password is acceptable.
                </TextExtraSmallInfo>
              ) : passwordStrength > 3 ? (
                <TextExtraSmallSuccess margin="0 0 0 10px">
                  You are using a strong password.
                </TextExtraSmallSuccess>
              ) : null}
            </FlexContainer>
            <FlexContainer
              padding="0 10px 0 10px"
              style={{ flex: 1 }}
              minWidth="230px"
              gap={DefaultBoxGap}
            >
              <TextSmall>{t("Confirm Password")}</TextSmall>
              <InputOutlined
                margin="0"
                width="100%"
                type="password"
                value={confirmPassword}
                onChange={(e: any) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder={t("Confirm Password")}
              />
              {confirmError && (
                <TextExtraSmallError
                  style={{ textAlign: "start" }}
                  margin="0 0 0 10px"
                >
                  {confirmError}
                </TextExtraSmallError>
              )}
            </FlexContainer>
            
            <FlexContainer>
              <TextSmallError>{error}</TextSmallError>
            </FlexContainer>
            <Divider />
            <FlexContainer
              breakDirection="row"
              alignVertical="flex-end"
              gap={DefaultBoxGap}
            >
              <ButtonOutlinedDimmed
                margin="0"
                borderRadius={DefaultBorderRadius}
                onClick={() => handleClose()}
              >
                {t("LabelCancel")}
              </ButtonOutlinedDimmed>
              <ButtonOutlinedDimmed
                margin="0"
                borderRadius={DefaultBorderRadius}
                disabled={disabled}
                onClick={() => {
                  setOpenConfirm(true);
                }}
              >
                {saving ? (
                  <DotsLoader>
                    <Dot delay="0s" />
                    <Dot delay="0.1s" />
                    <Dot delay="0.2s" />
                  </DotsLoader>
                ) : (
                  t("Create")
                )}
              </ButtonOutlinedDimmed>
            </FlexContainer>
          </FlexContainer>
        </Box>
      </Modal>
      <CommonModal
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        title="Confirm"
        content="You need to pay $5 for new secondary account"
        callback={handleSubmit}
      />
    </>
  );
};

export default NewSubUserModal;
