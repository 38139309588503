import { useCallback, useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";
import { deleteSession, getUserSessions } from "../api/userApiCall";

const useSessions = () => {
  const [sessions, setSessions] = useState<any[] | undefined>();

  const { accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    getUserSessions(accessToken)
      .then((data) => {
        if (data && Array.isArray(data)) {
          const sorted = data.sort((session1: any, session2: any) => {
            if (session2?.isCurrent) return 1;
            else if (session1?.isCurrent) return -1;
            else if (session1.last_login && session2.last_login) {
              const date1 = new Date(session1.last_login).getTime();
              const date2 = new Date(session2.last_login).getTime();

              if (date1 > date2) return 1;
              else if (date1 < date2) return -1;
              else return 0;
            } else if (session1.last_login) {
              return 1;
            } else if (session2.last_login) {
              return -1;
            } else return 0;
          });
          setSessions(sorted);
        }
      })
      .catch((err: any) => {
        // console.log(err.message);
      });
  }, [accessToken]);

  const terminateSession = useCallback(
    (session_id: string) => {
      return deleteSession(session_id, accessToken).then(() => [
        triggerFetch()
      ]);
    },
    [accessToken, triggerFetch]
  );

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { sessions, terminateSession };
};

export default useSessions;
