import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { getUser, userLogout } from "../api/userApiCall";
import { toast } from "react-toastify";
import useSessionStorage from "./useSessionStorage";

export const useProvideAuth = () => {
  const [accessTokenInLocalStorage, setAccessTokenInLocalStorate] =
    useLocalStorage("access-token", "");
  const [_accessToken, _setAccessToken] = useSessionStorage("access-token", "");
  const [loginType, setLoginType] = useState(false);
  const fetching = useRef<boolean>(false);

  const [user, setUser] = useState<any>();
  const [permission, setPermission] = useState<any>();

  const accessToken = useMemo(() => {
    if (_accessToken && _accessToken !== "") {
      return _accessToken;
    } else if (accessTokenInLocalStorage && accessTokenInLocalStorage !== "") {
      return accessTokenInLocalStorage;
    } else return "";
  }, [accessTokenInLocalStorage, _accessToken]);

  const setAccessToken = useCallback(
    (token: string) => {
      if (loginType) {
        _setAccessToken("");
        setAccessTokenInLocalStorate(token);
      } else {
        setAccessTokenInLocalStorate("");
        _setAccessToken(token);
      }
    },
    [loginType, setAccessTokenInLocalStorate, _setAccessToken]
  );

  const triggerFetch = useCallback(() => {
    if (accessToken && accessToken !== "" && !fetching.current) {
      fetching.current = true;
      getUser(accessToken)
        .then((user) => {
          if (user) {
            setUser(user);
            if (user.permission) {
              setPermission(user.permission);
            }
          }
        })
        .catch((err) => {
          setUser(null);
          if (err.message === "jwt expired") {
            setAccessTokenInLocalStorate("");
          } else if (err.message === "LogoutByOther") {
            toast.error("You have been logged out");
            setAccessTokenInLocalStorate("");
          }
        })
        .finally(() => {
          fetching.current = false;
        });
    } else {
      setUser(null);
    }
  }, [accessToken, setAccessTokenInLocalStorate, fetching]);

  const logout = useCallback(() => {
    return userLogout(accessToken).then(() => {
      setAccessTokenInLocalStorate("");
      _setAccessToken("");
    });
  }, [setAccessTokenInLocalStorate, _setAccessToken, accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return {
    accessToken,
    setAccessToken,
    user,
    permission,
    triggerFetch,
    logout,
    loginType,
    setLoginType
  };
};
