import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoX,
  LinkWrapper,
  Modal,
  Spacer,
  Text
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../../../theme";
import { useModal } from "../../../contexts/ModalContext";
import { usePrompt } from "../../../contexts/PromptContext";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { updatePrompt } from "../../../api/promptApiCall";
import { PromptForApi } from "../../../types/prompt";
import { useSearchParams } from "react-router-dom";
import { useTranslate } from "../../../contexts/SettingContext";
import { sendMessageToAi } from "../../../api/aiApiCall";
import {
  PromptDescription,
  PromptTitle
} from "../../../constants/preFormattedRequests";

const UpdatePromptModal = () => {
  const { isOpen, handleClose } = useModal("updatePrompt");
  const { messages } = usePrompt();
  const { user, accessToken } = useUser();
  const { t } = useTranslate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [searchParams] = useSearchParams();

  const [savingToCloud, setSavingToCloud] = useState(false);

  const handleSaveToCloud = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const prompt_id = searchParams.get("prompt_id");
      if (!prompt_id || prompt_id === "" || Number.isNaN(prompt_id)) return;
      setSavingToCloud(true);
      const promptData: PromptForApi = {
        title,
        description,
        prompt_id: Number(prompt_id),
        user_id: user.user_id,
        organisation_id: 1,
        llm_engine_id: 1,
        fine_tuning_id: 1,
        prompt: JSON.stringify(messages)
      };

      updatePrompt(promptData, accessToken)
        .then((response) => {
          if (response.result && response.prompt_id) {
            // add success logic here . ex. show success alert
          }
          setSavingToCloud(false);
        })
        .catch(() => {
          setSavingToCloud(false);
        })
        .finally(() => {
          setTitle("");
          setDescription("");
          handleClose();
        });
    },
    [accessToken, searchParams, messages, handleClose, user, title, description]
  );

  useEffect(() => {
    if (isOpen && messages?.length) {
      const _messages = messages.map((message) => {
        if (message?.type === "photo") {
          return {
            ...message,
            content: "AI_Image"
          };
        } else return message;
      });
      const payload = {
        context: _messages,
        content: PromptTitle
      };
      sendMessageToAi("GPT35", payload, accessToken)
        .then((response) => {
          if (response) {
            if (response.choices?.[0]?.message?.content) {
              const title = response.choices[0].message.content;
              setTitle(title);
            }
            // add success logic here . ex. show success alert
          }
        })
        .catch(() => {});
      const desciptionPayload = {
        context: _messages,
        content: PromptDescription
      };
      sendMessageToAi("GPT35", desciptionPayload, accessToken)
        .then((response) => {
          if (response) {
            if (response.choices?.[0]?.message?.content) {
              const title = response.choices[0].message.content;
              setDescription(title);
            }
            // add success logic here . ex. show success alert
          }
        })
        .catch(() => {});
    }
  }, [isOpen, messages, accessToken]);

  const loading = useMemo(() => {
    if (title === "" || description === "") return true;
    else return false;
  }, [title, description]);

  return (
    <Modal
      width="auto"
      open={isOpen}
      handleClose={() => {
        setTitle("");
        setDescription("");
        handleClose();
      }}
    >
      <Box
        height="auto"
        maxHeight="50vh"
        width="auto"
        overflow="hidden"
        minWidth="40vw"
      >
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>{t("LabelSavePrompt")}</Heading>
          <LinkWrapper
            onClick={(e: any) => {
              e.preventDefault();
              setTitle("");
              setDescription("");
              handleClose();
            }}
          >
            <Box padding="0">
              <IcoX margin="0" />
            </Box>
          </LinkWrapper>
        </FlexContainer>
        <Divider gap="3px" />
        <>
          {loading ? (
            <LoaderPaddingWrapper>
              <DotsLoader>
                <Dot delay="0s" />
                <Dot delay="0.1s" />
                <Dot delay="0.2s" />
              </DotsLoader>
            </LoaderPaddingWrapper>
          ) : (
            <FlexContainer
              style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}
            >
              <FlexContainer>
                <Text style={{ lineHeight: "1.5em" }}>
                  {t("LabelUpdatePromptDescription")}
                </Text>
              </FlexContainer>
              <Spacer gap="10px" />
              <FlexContainer
                gap={DefaultBoxGap}
                breakDirection="row"
                alignHorizontal="center"
                alignVertical="center"
              >
                <ButtonOutlinedDimmed
                  margin="0"
                  borderRadius={DefaultBorderRadius}
                  onClick={() => {
                    setTitle("");
                    setDescription("");
                    handleClose();
                  }}
                >
                  {t("LabelNo")}
                </ButtonOutlinedDimmed>
                <ButtonOutlinedDimmed
                  margin="0"
                  borderRadius={DefaultBorderRadius}
                  onClick={handleSaveToCloud}
                >
                  {savingToCloud ? (
                    <DotsLoader>
                      <Dot delay="0s" />
                      <Dot delay="0.1s" />
                      <Dot delay="0.2s" />
                    </DotsLoader>
                  ) : (
                    <>{t("LabelYes")}</>
                  )}
                </ButtonOutlinedDimmed>
              </FlexContainer>
            </FlexContainer>
          )}
        </>
      </Box>
    </Modal>
  );
};

export default UpdatePromptModal;
