import {
  ButtonOutlinedDimmed,
  FlexContainer,
  Heading,
  Spacer,
  Text
} from "llane-ui";
import { DefaultBorderRadius, DefaultBoxGap } from "../../../theme";
import { useTranslate } from "../../../contexts/SettingContext";
import { useUser } from "../../../contexts/UserContext";
import { useMemo, useState } from "react";
import SubscriptionModal from "../SubscriptionModal";
import { manageSubscription } from "../../../api/subscriptionApiCall";
import BillingHistory from "../../BillingHistory";
import { getDateStr } from "../../../utils";
import { useModal } from "../../../contexts/ModalContext";

const BillingSettings = () => {
  const { t } = useTranslate();

  const { user, accessToken } = useUser();

  const { handleOpen } = useModal("subscription");

  const plan = useMemo(() => {
    if (user) {
      const expire = getDateStr(user.expire_date);
      if (!user.tier_id) {
        return "No plan";
      } else if (user.tier_id === 1) {
        return "Free";
      } else if (user.tier_id === 2) {
        return "Premium (Expire at " + expire + ")";
      } else if (user.tier_id === 3) {
        return "Professional (Expire at " + expire + ")";
      } else if (user.tier_id === 4) {
        return "Enterprise (Expire at " + expire + ")";
      } else {
        return "Free";
      }
    } else {
      return "No plan";
    }
  }, [user]);

  const manage = () => {
    manageSubscription(accessToken)
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          if (data && data.redirectUrl) {
            window.open(data.redirectUrl, "_blank");
          }
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
        <FlexContainer
          alignVertical="space-between"
          alignHorizontal="center"
          breakDirection="row"
          width="100%"
        >
          <Text>
            <b>Current Plan</b>
            <br />
            {plan}
          </Text>
          <ButtonOutlinedDimmed
            margin="0"
            borderRadius={DefaultBorderRadius}
            onClick={() => {
              handleOpen();
            }}
          >
            {" "}
            {t("Upgrade or Downgrade")}
          </ButtonOutlinedDimmed>
        </FlexContainer>
        {user.tier_id > 1 ? (
          <FlexContainer
            alignVertical="space-between"
            alignHorizontal="center"
            breakDirection="row"
            width="100%"
          >
            <Text>{t("LabelMySubscription")}</Text>
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => {
                manage();
              }}
            >
              {t("LabelManage")}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        ) : null}
        <Spacer />
        <FlexContainer
          alignVertical="space-between"
          alignHorizontal="center"
          breakDirection="row"
          width="100%"
        >
          <Heading>{t("LabelAccountBalance")}</Heading>
          <Heading>
            <b>$0.00</b>
          </Heading>
        </FlexContainer>

        <Spacer gap="0" />

        <BillingHistory />
      </FlexContainer>
    </>
  );
};

export default BillingSettings;
