import { useRef, useState } from "react";
import { userLogin } from "../../api/userApiCall";
import { useUser } from "../../contexts/UserContext";
import { Link, Navigate } from "react-router-dom";
import {
  Button,
  FlexContainer,
  HeadingLarge,
  InputOutlined,
  PageWrapper,
  Spacer,
  Text
} from "llane-ui";
import { useTranslate } from "../../contexts/SettingContext";
import { toast } from "react-toastify";
import CommonModal from "../../components/Modals/Confirmations/CommonModal";
import { DefaultBoxGap } from "../../theme";

export function Login() {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isForceLogin, setIsForceLogin] = useState(false);
  const ref = useRef(false);

  const { setAccessToken, user, loginType, setLoginType } = useUser();

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = (isForce = false) => {
    if (email && password && !ref.current) {
      ref.current = true;
      setLoading(true);
      userLogin({
        email,
        password,
        isForce: isForce ?? undefined,
        rememberMe: loginType
      })
        .then((response) => {
          toast.success("Successfully Logged In");
          const { token } = response;
          if (token) setAccessToken(token);
        })
        .catch((err) => {
          if (err.message === "Sessions_Error") {
            setIsForceLogin(true);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => {
          ref.current = false;
          setLoading(false);
        });
    }
  };

  const forceLogin = () => {
    return new Promise<void>((resolve, reject) => {
      if (email && password && !ref.current) {
        ref.current = true;
        userLogin({ email, password, isForce: true })
          .then((response) => {
            toast.success("Successfully Logged In");
            const { token } = response;
            if (token) setAccessToken(token);
            resolve();
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => {
            ref.current = false;
          });
      }
    });
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageWrapper>
        <Spacer gap="5%" />
        <FlexContainer
          breakDirection="row"
          alignHorizontal="center"
          alignVertical="center"
          onKeyDown={_handleKeyDown}
        >
          <FlexContainer
            padding="10px"
            alignText="center"
            maxWidth="100%"
            width="450px"
            gap={DefaultBoxGap}
          >
            <HeadingLarge>{t("LabelLogin")}</HeadingLarge>
            <Spacer />
            <InputOutlined
              value={email}
              name="email"
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder={t("LabelEmail")}
              width="100%"
            />
            <InputOutlined
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              placeholder={t("LabelPassword")}
              name="password"
              type="password"
              width="100%"
            />
            <FlexContainer
              style={{ textAlign: "start", margin: 10, width: "100%" }}
              breakDirection="row"
              gap="5px"
            >
              <input
                type="checkbox"
                checked={loginType}
                onChange={(e: any) => {
                  setLoginType(e.target.checked);
                }}
              />{" "}
              <Text>Remember My Account</Text>
            </FlexContainer>
            <Button width="100%" onClick={() => handleLogin()}>
              {loading ? `${t("LabelSubmitting")}` : `${t("LabelLogin")}`}
            </Button>
            <FlexContainer breakDirection="row" gap="20px" width="100%">
              <Link
                to="/signup"
                style={{ textDecoration: "none", margin: "auto" }}
              >
                <Text>
                  Don't have an account yet? <b>Sign up now.</b>
                </Text>
              </Link>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <Spacer gap="5%" />
        <CommonModal
          open={isForceLogin}
          title="2 Sessions Exist"
          content="You have logged in from another device. Arcadia allows for only
                two devices logged in at a time. If you log back in, your oldest
                session on your other device will be terminated."
          callback={forceLogin}
          handleClose={() => {
            setIsForceLogin(false);
          }}
        />
      </PageWrapper>
    </>
  );
}

export default Login;
