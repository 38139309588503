import {
  ButtonOutlinedDimmed,
  FlexContainer,
  InputOutlined,
  TextSmall
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader
} from "../../../theme";
import { useTranslate } from "../../../contexts/SettingContext";
import { useUser } from "../../../contexts/UserContext";
import { useCallback, useEffect, useState } from "react";
import { updateProfiile } from "../../../api/userApiCall";

const ProfileSettings = () => {
  const { t } = useTranslate();

  const [saving, setSaving] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { user, accessToken, triggerFetch } = useUser();

  const initValue = useCallback(() => {
    if (user) {
      if (user.first_name) {
        setFirstName(user.first_name);
      }
      if (user.last_name) {
        setLastName(user.last_name);
      }
      if (user.email) {
        setEmail(user.email);
      }
      if (user.phone) {
        setPhone(user.phone);
      }
    }
  }, [user]);

  const handleUpdateUser = () => {
    const user_id = user.user_id;

    const payload = {
      user_id,
      first_name: firstName,
      last_name: lastName,
      phone
    };

    setSaving(true);
    updateProfiile(payload, accessToken)
      .then(() => {
        triggerFetch();
      })
      .catch((err: any) => {
        // console.log(err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    initValue();
  }, [initValue]);

  return (
    <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
      <FlexContainer
        breakDirection="row"
        width="100%"
        gap={DefaultBoxGap}
        style={{ flexWrap: "wrap" }}
      >
        <FlexContainer
          padding="0 10px 0 10px"
          gap={DefaultBoxGap}
          minWidth="200px"
          style={{ flex: 1 }}
        >
          <TextSmall>{t("LabelFirstName")}</TextSmall>
          <InputOutlined
            width="100%"
            placeholder={t("LabelFirstName")}
            style={{ boxSizing: "border-box" }}
            value={firstName}
            onChange={(e: any) => {
              setFirstName(e.target.value);
            }}
          />
        </FlexContainer>
        <FlexContainer
          padding="0 10px 0 10px"
          gap={DefaultBoxGap}
          minWidth="200px"
          style={{ flex: 1 }}
        >
          <TextSmall>{t("LabelLastName")}</TextSmall>
          <InputOutlined
            width="100%"
            placeholder={t("LabelLastName")}
            value={lastName}
            onChange={(e: any) => {
              setLastName(e.target.value);
            }}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        breakDirection="row"
        width="100%"
        gap={DefaultBoxGap}
        style={{ flexWrap: "wrap" }}
      >
        <FlexContainer
          padding="0 10px 0 10px"
          gap={DefaultBoxGap}
          minWidth="200px"
          style={{ flex: 1 }}
        >
          <TextSmall>{t("LabelPrimaryEmail")}</TextSmall>
          <InputOutlined
            width="100%"
            placeholder={t("LabelPrimaryEmail")}
            value={email}
            disabled
          />
        </FlexContainer>
        <FlexContainer
          padding="0 10px 0 10px"
          gap={DefaultBoxGap}
          minWidth="200px"
          style={{ flex: 1 }}
        >
          <TextSmall>{t("LabelContactPhone")}</TextSmall>
          <InputOutlined
            width="100%"
            placeholder={t("LabelContactPhone")}
            value={phone}
            onChange={(e: any) => {
              setPhone(e.target.value);
            }}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        breakDirection="row"
        alignVertical="flex-end"
        gap={DefaultBoxGap}
      >
        <ButtonOutlinedDimmed
          margin="0"
          borderRadius={DefaultBorderRadius}
          onClick={() => {
            initValue();
          }}
        >
          {t("LabelCancel")}
        </ButtonOutlinedDimmed>
        <ButtonOutlinedDimmed
          margin="0"
          borderRadius={DefaultBorderRadius}
          onClick={() => {
            handleUpdateUser();
          }}
        >
          {saving ? (
            <DotsLoader>
              <Dot delay="0s" />
              <Dot delay="0.1s" />
              <Dot delay="0.2s" />
            </DotsLoader>
          ) : (
            t("LabelSave")
          )}
        </ButtonOutlinedDimmed>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProfileSettings;
