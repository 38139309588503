import { toast } from "react-toastify";
import { sendMessageToAi } from "../api/aiApiCall";
import { ChatMessage } from "../types/prompt";

const createImage = async (
  argument: string,
  accessToken: string,
  signal?: AbortSignal
) => {
  const payload = {
    content: argument,
    context: []
  };
  const response = await sendMessageToAi(
    "ArcadiaImage",
    payload,
    accessToken,
    signal
  )
    .then((response) => {
      return {
        subject: "AI",
        content: response.images[0].toString("base64"),
        type: "photo"
      } as ChatMessage;
    })
    .catch((err) => {
      if (err.message === "Too many request") {
        const { ratelimit } = err;
        if (ratelimit) {
          const min = Math.floor(ratelimit.msBeforeNext / 1000 / 60) ?? 1;
          toast.error(
            `You have exceeded your usage limit for this type of request. Please try again in ${min} minutes.`
          );
        }
      } else if (err.message === "Bad Words") {
        const data: ChatMessage = {
          subject: "AI",
          content:
            "I'm sorry but your request does not comply with our User Safety Policy and will not be processed. Repeated infringements may result in your account being banned.",
          type: "text"
        };
        return data;
      }
      return null;
    });

  return response;
};

export const defaultCommands = [{ command: "image", callback: createImage }];
