import {
  Box,
  ButtonOutlined,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoPlus,
  IcoX,
  InputOutlined,
  LinkWrapper,
  Modal,
  Spacer,
  Text,
  TextAreaOutlined
} from "llane-ui";
import { useState } from "react";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader
} from "../../theme";
import { useTranslate } from "../../contexts/SettingContext";
import { useUser } from "../../contexts/UserContext";
import { saveCustomCommand } from "../../api/customCommandApiCall";
import { usePrompt } from "../../contexts/PromptContext";

const NewCustomCommandModal = () => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);

  const [command, setCommand] = useState("");
  const [commandReturn, setCommandReturn] = useState("");

  const [saving, setSaving] = useState(false);
  const { accessToken } = useUser();
  const { fetchCustomCommands } = usePrompt();

  const handleSubmit = () => {
    const payload = {
      command,
      command_return: commandReturn
    };
    setSaving(true);

    saveCustomCommand(payload, accessToken)
      .finally(() => {
        setSaving(false);
        setOpen(false);
        fetchCustomCommands();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <FlexContainer margin="0 1rem">
        <ButtonOutlined
          title={t("LabelCreateCustomCommand")}
          onClick={() => setOpen(!open)}
        >
          <IcoPlus size="24px" margin="auto" />
        </ButtonOutlined>
      </FlexContainer>
      <Modal width="auto" open={open} handleClose={() => setOpen(false)}>
        <Box
          height="auto"
          width="750px"
          minHeight="60vh"
          maxHeight="90vh"
          overflow="hidden"
        >
          <FlexContainer alignVertical="space-between" breakDirection="row">
            <Heading>{t("LabelCustomCommand")}</Heading>
            <LinkWrapper onClick={() => setOpen(false)}>
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            </LinkWrapper>
          </FlexContainer>
          <Divider gap="3px" />
          <FlexContainer
            breakDirection="column"
            gap={DefaultBoxGap}
            style={{ flex: 1, overflow: "auto" }}
          >
            <FlexContainer>
              <Text style={{ lineHeight: "1.5em" }}>
                {t("LabelCustomCommandCreationInstruction")}
              </Text>
            </FlexContainer>
            <FlexContainer>
              <InputOutlined
                width="100%"
                style={{ boxSizing: "border-box" }}
                placeholder={t("LabelTypeCommandHere")}
                value={command}
                onChange={(e: any) => {
                  setCommand(e.target.value);
                }}
              />
            </FlexContainer>
            <FlexContainer
              breakDirection="column"
              width="100%"
              gap={DefaultBoxGap}
            >
              <FlexContainer
                breakDirection="row"
                width="100%"
                gap={DefaultBoxGap}
              >
                <TextAreaOutlined
                  width="100%"
                  rows={15}
                  placeholder={t("LabelCommandReturnStatement")}
                  value={commandReturn}
                  onChange={(e: any) => {
                    setCommandReturn(e.target.value);
                  }}
                />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>

          <Spacer gap="0" />
          <FlexContainer
            breakDirection="row"
            alignVertical="flex-end"
            gap={DefaultBoxGap}
          >
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => setOpen(false)}
            >
              {t("LabelCancel")}
            </ButtonOutlinedDimmed>
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => {
                handleSubmit();
              }}
            >
              {saving ? (
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              ) : (
                t("LabelSaveClose")
              )}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        </Box>
      </Modal>
    </>
  );
};

export default NewCustomCommandModal;
