import { createBrowserRouter } from "react-router-dom";
// -- Page Imports A-Z
import Home from "./pages";
import CustomCommands from "./pages/CustomCommands";
// import Drive from "./pages/Drive";
import Login from "./pages/Auth/Login";
import FineTune from "./pages/FineTune";
import Prompt from "./pages/Prompt";
import Signup from "./pages/Auth/Signup";
import AppLayout from "./layouts/AppLayout";
import SavedPrompts from "./pages/SavedPrompts";
import { AuthLayout } from "./layouts/AuthLayout";
import ConfirmEmail from "./pages/Auth/Confirm";
import Verify from "./pages/Auth/Verify";

const router = createBrowserRouter([
  {
    element: <Verify />,
    path: "/verify"
  },
  {
    element: <AppLayout />,
    children: [
      { path: "/custom-commands", element: <CustomCommands /> },
      /* { path: "/drive", element: <Drive /> }, */
      { path: "/saved-prompts", element: <SavedPrompts /> },
      { path: "/fine-tune", element: <FineTune /> },
      { path: "/prompt", element: <Prompt /> }
    ]
  },
  {
    element: <AuthLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <Signup /> },
      { path: "/confirm", element: <ConfirmEmail /> }
    ]
  }
]);

export default router;
